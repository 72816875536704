import { createSlice } from '@reduxjs/toolkit';
import {
	addDeviceService,
	addFacilityAdd,
	fetchFacilityList,
	getDeviceTypesService,
	getFacilityDetails,
	getModemAndDevicesService,
} from '../../services/facility';

export const facilitySlice = createSlice({
	name: 'facility',
	initialState: {
		facilityId: null,
		facilityResponse: null,
		deviceResponse: null,
		facilityList: null,
		modemAndDeviceList: null,
		facilityData: null,
		editingDeviceData: null,
		deviceTypes: null,
		isLoading: false,
		isUpdate: false,
		isFacilityUpdate: false,
		error: null,
	},

	reducers: {
		openFacilityPageForUpdate: (state) => {
			state.isFacilityUpdate = true;
		},

		openFacilityPageForAddNew: (state) => {
			state.isFacilityUpdate = false;
		},

		setIsUpdate: (state, action) => {
			state.isUpdate = action.payload;
		},

		setEditingDeviceData: (state, action) => {
			const data = action.payload;
			state.editingDeviceData = {
				DeviceId: data.DeviceId,
				DeviceTitle: data.DeviceTitle,
				DeviceTypeId: data.DeviceTypeId,
				DeviceTypeTitle: data.DeviceTypeTitle,
				ModbusId: data.ModbusId,
				ModemId: data.ModemId,
				ModemKey: data.ModemKey,
				BaudRate: data.BaudRate,
				CurrentRatio: data.CurrentRatio,
			};
		},
	},

	extraReducers: (builder) => {
		//getfacilityList
		builder.addCase(fetchFacilityList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchFacilityList.fulfilled, (state, action) => {
			const { BaseResponse, FacilityList } = action.payload;

			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.facilityList = FacilityList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(fetchFacilityList.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.isLoading = false;
		});

		//getFacilityData
		builder.addCase(getFacilityDetails.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getFacilityDetails.fulfilled, (state, action) => {
			const { BaseResponse, Facility } = action.payload;
			// console.log(BaseResponse, Facility);
			if (BaseResponse.Success) {
				state.isLoading = false;
				state.facilityData = Facility;
				console.log(Facility);
				state.editingDeviceData = {
					DeviceId: Facility.DeviceId,
					DeviceTitle: Facility.DeviceTitle,
					DeviceTypeId: Facility.DeviceTypeId,
					DeviceTypeTitle: Facility.DeviceTypeTitle,
					ModbusId: Facility.ModbusId,
					ModemId: Facility.ModemId,
					ModemKey: Facility.ModemKey,
					BaudRate: Facility.BaudRate,
					CurrentRatio: Facility.CurrentRatio,
				};
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(getFacilityDetails.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.isLoading = false;
		});

		//getDeviceTypesService
		builder.addCase(getDeviceTypesService.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getDeviceTypesService.fulfilled, (state, action) => {
			const { BaseResponse, DeviceTypeList } = action.payload;
			if (BaseResponse.Success) {
				state.isLoading = false;
				state.deviceTypes = DeviceTypeList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(getDeviceTypesService.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.isLoading = false;
		});

		//getModemAndDeviceService
		builder.addCase(getModemAndDevicesService.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(getModemAndDevicesService.fulfilled, (state, action) => {
			const { BaseResponse, ModemAndDeviceList } = action.payload;
			// console.log(ModemAndDeviceList);
			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.modemAndDeviceList = ModemAndDeviceList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(getModemAndDevicesService.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.isLoading = false;
		});

		//addFacilityData
		builder.addCase(addFacilityAdd.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(addFacilityAdd.fulfilled, (state, action) => {
			const response = action.payload;
			if (!response.BaseResponse.Success) {
				state.error = response.BaseResponse.ResponseMessage;
				state.isLoading = false;
			} else {
				state.facilityId = response.FacilityId;
				state.facilityResponse = response;
				state.isLoading = false;
				state.error = null;
			}
		});

		builder.addCase(addFacilityAdd.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.isLoading = false;
		});

		//addDeviceData
		builder.addCase(addDeviceService.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(addDeviceService.fulfilled, (state, action) => {
			const response = action.payload;
			if (!response.BaseResponse.Success) {
				state.error = response.BaseResponse.ResponseMessage;
				state.isLoading = false;
			} else {
				state.deviceResponse = response;
				state.isLoading = false;
				state.error = null;
			}
		});

		builder.addCase(addDeviceService.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.isLoading = false;
		});
	},
});

export const {
	openFacilityPageForUpdate,
	openFacilityPageForAddNew,
	setIsUpdate,
	setEditingDeviceData,
} = facilitySlice.actions;

export default facilitySlice.reducer;
