import { createSlice } from '@reduxjs/toolkit';
import { fetchCurrentlyUnknownData } from '../../../../services/reports/filters/tag';

export const ReportsFiltersCurrentlyUnknownData = createSlice({
	name: 'ReportsFiltersCurrentlyUnknownData',
	initialState: {
		ReportsFiltersCurrentlyUnknownData: null,
		isLoading: false,
		error: null,
	},

	reducers: {},

	extraReducers: (builder) => {
		builder.addCase(fetchCurrentlyUnknownData.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchCurrentlyUnknownData.fulfilled, (state, action) => {
			const { BaseResponse, DataTypeList } = action.payload;

			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.ReportsFiltersCurrentlyUnknownData = DataTypeList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(fetchCurrentlyUnknownData.rejected, (state, action) => {
			state.error = action.error.message;
			state.isLoading = false;
		});
	},
});

export default ReportsFiltersCurrentlyUnknownData.reducer;
