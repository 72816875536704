
  export const  cities = [
      {
        "value": "Adana",
        "id": "01"
      },
      {
        "value": "Adıyaman",
        "id": "02"
      },
      {
        "value": "Afyonkarahisar",
        "id": "03"
      },
      {
        "value": "Ağrı",
        "id": "04"
      },
      {
        "value": "Amasya",
        "id": "05"
      },
      {
        "value": "Ankara",
        "id": "06"
      },
      {
        "value": "Antalya",
        "id": "07"
      },
      {
        "value": "Artvin",
        "id": "08"
      },
      {
        "value": "Aydın",
        "id": "09"
      },
      {
        "value": "Balıkesir",
        "id": "10"
      },
      {
        "value": "Bilecik",
        "id": "11"
      },
      {
        "value": "Bingöl",
        "id": "12"
      },
      {
        "value": "Bitlis",
        "id": "13"
      },
      {
        "value": "Bolu",
        "id": "14"
      },
      {
        "value": "Burdur",
        "id": "15"
      },
      {
        "value": "Bursa",
        "id": "16"
      },
      {
        "value": "Çanakkale",
        "id": "17"
      },
      {
        "value": "Çankırı",
        "id": "18"
      },
      {
        "value": "Çorum",
        "id": "19"
      },
      {
        "value": "Denizli",
        "id": "20"
      },
      {
        "value": "Diyarbakır",
        "id": "21"
      },
      {
        "value": "Edirne",
        "id": "22"
      },
      {
        "value": "Elazığ",
        "id": "23"
      },
      {
        "value": "Erzincan",
        "id": "24"
      },
      {
        "value": "Erzurum",
        "id": "25"
      },
      {
        "value": "Eskişehir",
        "id": "26"
      },
      {
        "value": "Gaziantep",
        "id": "27"
      },
      {
        "value": "Giresun",
        "id": "28"
      },
      {
        "value": "Gümüşhane",
        "id": "29"
      },
      {
        "value": "Hakkari",
        "id": "30"
      },
      {
        "value": "Hatay",
        "id": "31"
      },
      {
        "value": "Isparta",
        "id": "32"
      },
      {
        "value": "Mersin",
        "id": "33"
      },
      {
        "value": "İstanbul",
        "id": "34"
      },
      {
        "value": "İzmir",
        "id": "35"
      },
      {
        "value": "Kars",
        "id": "36"
      },
      {
        "value": "Kastamonu",
        "id": "37"
      },
      {
        "value": "Kayseri",
        "id": "38"
      },
      {
        "value": "Kırklareli",
        "id": "39"
      },
      {
        "value": "Kırşehir",
        "id": "40"
      },
      {
        "value": "Kocaeli",
        "id": "41"
      },
      {
        "value": "Konya",
        "id": "42"
      },
      {
        "value": "Kütahya",
        "id": "43"
      },
      {
        "value": "Malatya",
        "id": "44"
      },
      {
        "value": "Manisa",
        "id": "45"
      },
      {
        "value": "Kahramanmaraş",
        "id": "46"
      },
      {
        "value": "Mardin",
        "id": "47"
      },
      {
        "value": "Muğla",
        "id": "48"
      },
      {
        "value": "Muş",
        "id": "49"
      },
      {
        "value": "Nevşehir",
        "id": "50"
      },
      {
        "value": "Niğde",
        "id": "51"
      },
      {
        "value": "Ordu",
        "id": "52"
      },
      {
        "value": "Rize",
        "id": "53"
      },
      {
        "value": "Sakarya",
        "id": "54"
      },
      {
        "value": "Samsun",
        "id": "55"
      },
      {
        "value": "Siirt",
        "id": "56"
      },
      {
        "value": "Sinop",
        "id": "57"
      },
      {
        "value": "Sivas",
        "id": "58"
      },
      {
        "value": "Tekirdağ",
        "id": "59"
      },
      {
        "value": "Tokat",
        "id": "60"
      },
      {
        "value": "Trabzon",
        "id": "61"
      },
      {
        "value": "Tunceli",
        "id": "62"
      },
      {
        "value": "Şanlıurfa",
        "id": "63"
      },
      {
        "value": "Uşak",
        "id": "64"
      },
      {
        "value": "Van",
        "id": "65"
      },
      {
        "value": "Yozgat",
        "id": "66"
      },
      {
        "value": "Zonguldak",
        "id": "67"
      },
      {
        "value": "Aksaray",
        "id": "68"
      },
      {
        "value": "Bayburt",
        "id": "69"
      },
      {
        "value": "Karaman",
        "id": "70"
      },
      {
        "value": "Kırıkkale",
        "id": "71"
      },
      {
        "value": "Batman",
        "id": "72"
      },
      {
        "value": "Şırnak",
        "id": "73"
      },
      {
        "value": "Bartın",
        "id": "74"
      },
      {
        "value": "Ardahan",
        "id": "75"
      },
      {
        "value": "Iğdır",
        "id": "76"
      },
      {
        "value": "Yalova",
        "id": "77"
      },
      {
        "value": "Karabük",
        "id": "78"
      },
      {
        "value": "Kilis",
        "id": "79"
      },
      {
        "value": "Osmaniye",
        "id": "80"
      },
      {
        "value": "Düzce",
        "id": "81"
      }
    ]
  