import { useState, useEffect, useRef } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import { BsGraphUpArrow } from 'react-icons/bs';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const options = {
	responsive: true,
	interaction: {
		mode: 'index',
		intersect: false,
	},
	stacked: false,
	plugins: {
		title: {
			display: true,
			text: 'Antcom.com.tr',
		},
	},
	scales: {
		y: {
			type: 'linear',
			display: true,
			position: 'left',
		},
		y1: {
			type: 'linear',
			display: true,
			position: 'right',
			grid: {
				drawOnChartArea: false,
			},
		},
	},
};

const labels = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'8.',
	'9.',
	'10',
	'11',
	'12',
	'13',
];

export const data = {
	labels: [
		'Ocak',
		'Şubat',
		'Mart',
		'Nisan',
		'Mayıs',
		'Haziran',
		'Temmuz',
		'Ağustos',
		'Eylül',
		'Ekim',
		'Kasım',
		'Aralık',
		'qwdwq',
		'qwdqwdkqwdqwjlk',
		'qwdjwqdoqwjlkj',
		'1342132132131232',
		'o2ue021je1jklj',
		'qopweuqwpdlkj2139',
		'qwodojpqowdjqwpodjqw',
		'uwqpodpqwoıdpqwodıqw',
		'q81230wqdqwşdkqw',
		'2131231',
		'qwodojpqjqw',
		'qlşwdqwjl',
	],
	datasets: [
		{
			label: 'Dataset 1',
			data: [12, 32, 45, 65, 34, 54, 23, 66, 66, 78, 23, 68, 22],
			borderColor: 'rgb(255, 99, 132)',
			backgroundColor: 'rgba(255, 99, 132, 0.5)',
			yAxisID: 'y',
		},
		{
			label: 'Dataset 2',
			data: [12, 32, 23, 45, 23, 43, 53, 42, 87, 23, 45, 32, 66],
			borderColor: 'rgb(53, 162, 235)',
			backgroundColor: 'rgba(53, 162, 235, 0.5)',
			yAxisID: 'y1',
		},
		{
			label: 'Dataset 3',
			data: [43, 22, 53, 25, 43, 63, 43, 32, 57, 53, 25, 52, 56],
			borderColor: '#565676',
			backgroundColor: '#343434',
			yAxisID: 'y2',
		},
	],
	tension: 0.1,
	borderWidth: 1,
};

function convertBinaryDataToChartData(binaryData) {
	const labels = binaryData.map((entry) => {
		const date = new Date(entry.Time);
		return `${date.getFullYear()} ${date.toLocaleString('default', {
			month: 'long',
		})} ${date.getDay()} ${date.getHours()}:00`;
	});

	const dataset1 = binaryData.map((entry) => entry.Value);

	return {
		labels: labels,
		datasets: [
			{
				label: 'Dataset 1',
				data: dataset1,
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
				yAxisID: 'y',
			},
		],
		tension: 0.1,
		borderWidth: 1,
	};
}

function ChartGraph({ binaryData }) {
	const [chartData, setChartData] = useState(null);
	const chartRef = useRef(null);

	const exportChartToPNG = () => {
		if (chartRef.current) {
			html2canvas(chartRef.current)
				.then((canvas) => {
					canvas.toBlob((blob) => {
						const url = window.URL.createObjectURL(blob);

						const a = document.createElement('a');
						a.href = url;
						a.download = 'chart.png';
						a.click();

						window.URL.revokeObjectURL(url);
					}, 'image/png');
				})
				.catch((error) => {
					console.error('PNG dışa aktarma hatası:', error);
				});
		}
	};

	useEffect(() => {
		if (binaryData.length > 0) {
			const convertedData = convertBinaryDataToChartData(binaryData);
			setChartData(convertedData);
		}
	}, [binaryData]);

	return (
		<>
			<div className="w-full border-b-2 border-slate-500 dark:border-b-white flex items-center justify-end">
				<div className="flex items-center justify-end">
					<BsGraphUpArrow
						className="w-10 h-10 text-white bg-skyCustom rounded-md p-1 m-1 hover:cursor-pointer"
						onClick={exportChartToPNG}
					/>
				</div>
			</div>

			<div className="max-w-full w-full h-400" ref={chartRef}>
				{chartData && <Line data={chartData} options={options} />}
			</div>
		</>
	);
}

export default ChartGraph;
