import startOfMonth from 'date-fns/startOfMonth';
import subDays from 'date-fns/subDays';

export const PredefinedRanges = [
	{
		label: 'Son 3 gün',
		value: [subDays(new Date(), 3), new Date()],
		placement: 'left',
	},

	{
		label: 'Son 7 gün',
		value: [subDays(new Date(), 7), new Date()],
		placement: 'left',
	},

	{
		label: 'Son 15 gün',
		value: [subDays(new Date(), 15), new Date()],
		placement: 'left',
	},

	{
		label: 'Son 30 gün',
		value: [subDays(new Date(), 30), new Date()],
		placement: 'left',
	},

	{
		label: 'Güncel ay',
		value: [startOfMonth(new Date()), new Date()],
		placement: 'left',
	},

	// {
	// 	label: 'Geçen ay',
	// 	value: [
	// 		startOfMonth(addMonths(new Date(), -1)),
	// 		endOfMonth(addMonths(new Date(), -1)),
	// 	],
	// 	placement: 'left',
	// },

	{
		label: 'Son 3 gün',
		closeOverlay: false,
		value: [subDays(new Date(), 3), new Date()],
		appearance: 'default',
	},

	{
		label: 'Son 7 gün',
		closeOverlay: false,
		value: [subDays(new Date(), 7), new Date()],
		appearance: 'default',
	},

	{
		label: 'Son 15 gün',
		closeOverlay: false,
		value: [subDays(new Date(), 15), new Date()],
		appearance: 'default',
	},

	{
		label: 'Son 30 gün',
		closeOverlay: false,
		value: [subDays(new Date(), 30), new Date()],
		appearance: 'default',
	},

	{
		label: 'Güncel ay',
		closeOverlay: false,
		value: [startOfMonth(new Date()), new Date()],
		appearance: 'default',
	},
];
