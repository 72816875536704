import React, { useEffect, useState } from 'react';
import FacilityEditForm from '../../components/facility/form-facility-edit';
import FacilityDeviceEditForm from '../../components/facility/form-device-edit';
import { useDispatch, useSelector } from 'react-redux';
import {
	getDeviceTypesService,
	addFacilityAdd,
	getFacilityDetails,
} from '../../services/facility';
import { openFacilityPageForUpdate } from '../../store/facilitySlice';
import Loading from '../Loading';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

function FacilityAddPage() {
	const facility = useSelector((state) => state.falicity);
	const user = useSelector((state) => state.auth.user);

	const [addNewDevice, setAddNewDevice] = useState(false);

	const dispatch = useDispatch();

	const getDeviceTypesServiceData = async (request) => {
		await dispatch(getDeviceTypesService());
	};

	const getData = async (facilityRequest) => {
		await dispatch(getFacilityDetails(facilityRequest));
	};

	const handleAddFacility = async (facilityRequest) => {
		console.log(facilityRequest);
		if (facilityRequest) {
			const response = await dispatch(addFacilityAdd(facilityRequest));

			const facilityId = response.payload.FacilityId;
			const modemId = response.payload.ModemId;
			const deviceId = '';
			// console.log(response.payload);
			console.log(facilityId, modemId, deviceId);
			console.log(response.payload);
			if (response.payload.BaseResponse.Success) {
				Swal.fire('Yeni Tesis Eklendi...').then((result) => {
					if (result.isConfirmed) {
						const facilityIdData = {
							facilityId,
							modemId,
							deviceId,
						};
						dispatch(openFacilityPageForUpdate(facilityId, modemId));
						localStorage.setItem(
							'editFacilityIds',
							JSON.stringify(facilityIdData)
						);
						window.location.href = '/facilities/facility-edit';
					}
				});
				// toast.success("Yeni Tesis Eklendi...", {
				//   position: "top-center",
				//   autoClose: 5000,
				//   hideProgressBar: false,
				//   closeOnClick: true,
				//   pauseOnHover: true,
				//   draggable: true,
				//   progress: undefined,
				//   theme: "light",
				// });

				const facilityRequest = {
					facilityId: response.payload.facilityId,
					modemId: response.payload.modemId,
					// deviceId: editFacilityIds.deviceId,
				};
				await getData(facilityRequest);
			} else {
				toast.error(`Hata: ${response.payload.BaseResponse.ResponseMessage}`, {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
			}
		}
	};

	useEffect(() => {
		getDeviceTypesServiceData();
	}, []);

	if (facility.isLoading && !facility.deviceTypes) {
		return <Loading />;
	}
	return (
		<>
			{!facility.isLoading && facility.deviceTypes && (
				<section className="pt-16 mx-8  min-h-screen">
					<div className="min-w-310 shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8">
						<h3 className="mx-4 pt-4 w-full text-center text-slate-600 dark:text-white tracking-wider text-lg font-medium">
							Tesis Ekleme
						</h3>

						<FacilityEditForm
							editFacilityData={{}}
							editFacilityIds={[]}
							handleUpdateFacility={handleAddFacility}
							user={user}
						/>

						<div className="w-full px-4">
							<div className="border-b border-b-slate-300"></div>
						</div>

						<FacilityDeviceEditForm
							editFacilityData={{}}
							editDeviceData={{}}
							deviceTypeList={facility.deviceTypes}
							// handleUpdateDevice={handleUpdateDevice}
							// handleAddNewDevice={handleAddNewDevice}
							// addNewDevice={addNewDevice}
							setAddNewDevice={setAddNewDevice}
						/>
					</div>
				</section>
			)}
		</>
	);
}

export default FacilityAddPage;
