import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RxReader } from "react-icons/rx";
import { MdLocationOn } from "react-icons/md";
import { useLocation } from "react-router-dom";
import FormInput from "../../components/base/form-input";
import FormDropdown from "../../components/base/form-dropdown";
import { cities } from "../../mockData/city";
import { facilityGroups } from "../../mockData/facilityGroups";
import { modemGroups } from "../../mockData/modemGroups";
import AddFacilityForm from "./form-add-facility";
import { useDispatch, useSelector } from "react-redux";
import {  getFacilityDetails } from "../../services/facility";
import { setDeviceTypes } from "../../store/facilitySlice";
import Loading from "../Loading";
import AddDevice from "./form-add-device";
import { toast } from "react-toastify";

const fakeDeviceList = [
  {
    cihaz_adi: "Reaktif Röle",
    modem_no: "120210001",
    cihaz: "Grup Arge SVC12C3L",
    modbus: "255",
    baud_rate: "19200",
    status: true,
  },
  {
    cihaz_adi: "Reaktif Röle",
    modem_no: "120210001",
    cihaz: "Grup Arge SVC12C3L",
    modbus: "255",
    baud_rate: "19200",
    status: false,
  },
  {
    cihaz_adi: "Reaktif Röle",
    modem_no: "120210001",
    cihaz: "Grup Arge SVC12C3L",
    modbus: "255",
    baud_rate: "19200",
    status: true,
  },
];

function AddFacility() {
  const [veriler, setVeriler] = useState(fakeDeviceList);

  const [isUpdate, setIsUpdate] = useState(false);

  const facilityData = useSelector((state) => state.falicity);

  const dispatch = useDispatch();
  const location = useLocation();

  const facilityId = location.pathname.split("/")[3];
  const deviceId = location.pathname.split("/")[4];
  const modemId = location.pathname.split("/")[4];

  // 10'dan az veri varsa, diğer satırları boş dizilerle doldurun
  if (veriler.length < 10) {
    const bosDiziler = Array(10 - veriler.length).fill({});
    veriler.push(...bosDiziler);
  }

  const getData = async (facilityRequest) => {
    await dispatch(getFacilityDetails(facilityRequest));
  };

  // const getDevicesTypes = async () => {
  //   try {
  //     const data = await getDataDevices()
  //     console.log(data);
  //     if(data){
  //       dispatch(setDeviceTypes(data.DeviceTypeList))
  //     }
  //   } catch (error) {
  //     toast.error(`Hata...: ${error.message}`, {
  //       position: "top-center",
  //       autoClose: 3000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // };

  useEffect(() => {
    const facilityRequest = {
      facilityId,
      modemId,
      deviceId,
    };
    if (facilityId && modemId && deviceId) {
      setIsUpdate(true);
      getData(facilityRequest);
      // getDevicesTypes()
    }
  }, [facilityId, modemId, deviceId]);

  if (facilityData.isLoading) {
    return <Loading />;
  }

  return (
    <section className="pt-16 mx-8  min-h-screen">
      {/* Card */}
      <div className="min-w-310 shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8">
        <h3 className="mx-4 pt-4 w-full text-center text-slate-600 dark:text-white tracking-wider text-lg font-medium">
          Tesis Ekleme
        </h3>
        {/* Form */}
        <div>
          <AddFacilityForm
            facilidyData={facilityData.facilityData}
            isUpdate={isUpdate}
            facilityId={facilityId}
          />

          <div className="w-full px-4">
            <div className="border-b border-b-slate-300"></div>
          </div>
          {/* Device Add */}
          <AddDevice />
        </div>
      </div>
      {/* Table */}
      <div className="w-full shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8 overflow-x-auto">
        <table className="min-w-[1024px] w-full">
          <thead className="">
            <tr className="text-white tracking-wider bg-sky-500 rounded-t-md">
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2 text-sm py-2 rounded-tl-md">
                Etiket
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2  text-sm">
                Modem No
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2  text-sm">
                Cihaz
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2  text-sm">
                Modbus
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2 text-sm">
                Baud Rate
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Düzenle
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Sil
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Durum
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm rounded-tr-md">
                Cihaz Oku
              </th>
            </tr>
          </thead>
          <tbody>
            {veriler.map((veri, index) => (
              <tr
                key={index}
                className={`w-full ${
                  index % 2 === 0
                    ? "bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500"
                    : "bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500"
                } hover:bg-sky-100`}
              >
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm min-h-44 h-[40px]">
                  {veri.cihaz_adi || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.modem_no || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.modbus || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.baud_rate || ""}
                </td>
                <td className="py-1 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz_adi && (
                    <button
                      type="button"
                      className="h-full w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex gap-2 items-center justify-center"
                    >
                      <FaEdit />
                      Düzenle
                    </button>
                  )}
                </td>
                <td className="py-1 text-slate-950 dark:text-white px-2 text-sm">
                  {veri.cihaz_adi && (
                    <button
                      type="button"
                      className="w-full text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex items-center gap-2 justify-center"
                    >
                      <MdDelete />
                      Sil
                    </button>
                  )}
                </td>
                <td className="h-full w-full py-1 text-slate-950 dark:text-white pl-2 text-sm flex items-center justify-center">
                  {veri.cihaz_adi && (
                    <div
                      className={`w-4 h-4 mt-2 rounded-full ${
                        veri.status === true ? "bg-green-600" : "bg-red-600"
                      }`}
                    ></div>
                  )}
                </td>
                <td className="py-1 text-slate-950 dark:text-white  px-2 text-sm">
                  {veri.cihaz_adi && (
                    <button
                      type="button"
                      className="w-full text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex items-center justify-center gap-2"
                    >
                      <RxReader />
                      Veri Oku
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default AddFacility;
