import { useEffect } from "react";
import Logo from "../../assets/logo_tp2.png";
import { FaRegUserCircle, FaMoon } from "react-icons/fa";
import { FiBell } from "react-icons/fi";
import { BsFillSunFill } from "react-icons/bs";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

import Navbar from "./Navbar";

import { useSelector, useDispatch } from "react-redux";
import { set } from "../../store/themeSlice";
import { useNavigate } from "react-router-dom";
import {
  setToggleMenu,
  setMobileMenuOpen,
  setAvatarMenuOpen,
} from "../../store/menuSlice";

import AvatarMenu from "./AvatarMenu";

function Header() {
  const theme = useSelector((state) => state.theme);
  const { isMobileMenuOpen } = useSelector((state) => state.menu);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    theme === "dark"
      ? document.documentElement.classList.add("dark")
      : document.documentElement.classList.remove("dark");
    localStorage.setItem("theme", theme);
  }, [theme]);

  const handleChange = () => {
    const next = theme === "dark" ? "light" : "dark";
    dispatch(set(next));
  };

  const handleClick = () => {
    dispatch(setToggleMenu("/"));
    navigate("/", { replace: true });
  };

  return (
    <header className="h-12 fixed top-1 left-2 right-2 inset-0 rounded-full  bg-white border-b border-t border-b-slate-300 dark:border-b-slate-700 dark:border-t-slate-700 flex items-center justify-between shadow-lg dark:bg-bgNavDark z-50">
      {/* Logo & Menu */}
      <div className="h-full flex items-center justify-center hover:cursor-pointer">
        <div className="flex items-center mr-6" onClick={handleClick}>
          <img
            src={Logo}
            alt="Logo"
            className="w-20 ml-3 md:w-12 p-0 md:p-1 md:ml-2"
          />
          {/* <img src={AntcomLogo} alt="AntcomLogo" className="h-8 p-1" /> */}
          <div className="h-8 hidden md:block">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              // width="558.000000pt"
              height="24px"
              viewBox="0 0 558.000000 141.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)"
                fill={`${theme === "light" ? "#64748b" : "#FFF"}`}
                stroke="none"
              >
                <path
                  d="M1554 1380 c-118 -27 -215 -56 -331 -102 -91 -35 -108 -45 -151 -94
l-48 -55 -39 17 c-62 25 -228 15 -319 -20 l-70 -27 -40 42 c-32 32 -70 54
-171 96 -71 30 -156 66 -188 79 -31 13 -67 25 -80 25 -27 1 -100 -26 -104 -38
-2 -5 44 -14 102 -21 122 -13 270 -59 353 -109 60 -36 102 -71 102 -84 0 -5
-26 -26 -57 -47 -180 -123 -315 -343 -339 -550 l-6 -59 50 10 50 10 5 49 c11
90 70 193 166 289 115 115 171 142 301 147 94 4 96 4 145 -29 32 -22 62 -54
82 -89 32 -52 33 -58 33 -162 l0 -108 105 0 c105 0 105 0 118 28 7 17 12 80
12 167 0 134 -1 142 -29 199 -17 34 -54 84 -89 118 l-60 59 23 24 c30 32 65
43 180 55 52 5 128 19 169 30 41 12 107 27 147 35 115 22 154 50 154 109 0 24
-3 26 -47 25 -27 0 -85 -9 -129 -19z"
                />
                <path
                  d="M2425 965 c-5 -2 -22 -6 -36 -9 -25 -5 -27 -11 -34 -73 -9 -87 -24
-110 -80 -124 -38 -10 -45 -15 -45 -35 0 -22 4 -24 55 -24 l55 0 0 -115 0
-115 44 0 c24 0 51 -3 60 -6 13 -5 16 10 18 113 l3 118 123 3 122 3 0 29 0 29
-122 3 -123 3 -3 103 c-3 101 -5 108 -37 97z"
                />
                <path
                  d="M3134 800 c-147 -31 -276 -170 -291 -315 l-5 -52 47 -6 c86 -12 82
-16 88 73 6 97 36 164 87 198 30 20 45 23 100 20 64 -3 88 -12 187 -69 23 -13
44 -22 46 -19 3 3 7 33 9 67 l4 63 -55 21 c-63 23 -158 32 -217 19z"
                />
                <path
                  d="M3786 794 c-141 -45 -225 -158 -234 -316 l-5 -78 65 0 65 0 6 78 c10
125 37 190 98 234 56 42 150 40 209 -3 58 -43 100 -159 100 -275 l0 -55 68 3
67 3 3 34 c5 54 -17 160 -46 218 -30 63 -86 117 -150 145 -60 27 -181 33 -246
12z"
                />
                <path
                  d="M4744 801 c-43 -11 -98 -46 -128 -82 -14 -16 -27 -29 -30 -29 -3 0
-6 25 -6 55 0 32 -4 55 -10 55 -6 0 -56 -7 -110 -17 -86 -14 -100 -19 -100
-35 0 -13 15 -26 48 -40 26 -11 50 -26 54 -32 4 -6 8 -75 8 -153 l0 -143 60 0
60 0 0 134 0 135 33 25 c67 51 191 54 243 5 33 -31 44 -77 44 -182 l0 -89 53
7 c28 4 55 9 60 12 4 2 7 53 7 112 0 90 3 110 18 123 35 32 86 50 141 51 107
3 149 -35 160 -145 l6 -56 61 28 61 27 -8 59 c-14 104 -80 168 -187 181 -79 9
-135 -11 -203 -74 l-58 -54 -14 28 c-17 32 -51 65 -85 82 -29 15 -138 22 -178
12z"
                />
                <path
                  d="M1465 761 c-87 -14 -100 -19 -103 -37 -2 -16 8 -25 48 -44 l50 -23 0
-54 c0 -45 3 -53 19 -53 10 0 39 -3 65 -6 l46 -7 0 46 c0 37 5 49 26 66 37 29
99 44 166 39 78 -6 113 -38 129 -120 l12 -58 42 0 c24 0 50 -3 59 -6 13 -5 16
2 16 42 0 102 -39 176 -110 212 -95 48 -249 19 -314 -59 -14 -16 -27 -29 -30
-29 -3 0 -6 25 -6 55 0 30 -3 55 -7 54 -5 -1 -53 -9 -108 -18z"
                />
                <path
                  d="M1033 453 l-72 -4 -46 -64 c-53 -75 -134 -144 -219 -187 -64 -33
-144 -58 -186 -58 -19 0 -33 -13 -62 -58 l-37 -57 25 -13 c19 -10 56 -13 128
-10 95 3 107 6 208 51 59 26 109 47 111 47 3 0 8 -12 11 -26 7 -26 8 -27 72
-21 54 4 71 10 95 33 27 26 28 30 13 41 -29 21 -10 118 47 234 l48 99 -32 -2
c-18 -1 -64 -4 -104 -5z"
                />
                <path
                  d="M5408 433 l-58 -26 0 -119 0 -119 -45 -19 c-32 -14 -45 -25 -45 -40
0 -19 6 -20 160 -20 158 0 160 0 157 21 -3 15 -17 27 -48 38 l-44 17 -3 147
c-1 81 -6 147 -10 146 -4 0 -33 -12 -64 -26z"
                />
                <path
                  d="M1460 296 l0 -154 -50 -17 c-37 -12 -50 -22 -50 -36 0 -18 9 -19 160
-19 148 0 160 1 160 18 0 12 -15 25 -42 38 l-43 19 -3 153 -3 152 -64 0 -65 0
0 -154z"
                />
                <path
                  d="M1920 291 c0 -143 -3 -150 -65 -171 -24 -8 -35 -18 -35 -31 0 -18 9
-19 160 -19 151 0 160 1 160 19 0 14 -11 23 -37 32 -63 20 -63 20 -63 159 l0
127 -46 7 c-26 3 -53 6 -60 6 -11 0 -14 -27 -14 -129z"
                />
                <path
                  d="M2342 262 c3 -112 5 -122 28 -148 60 -64 175 -75 284 -25 56 25 66
33 66 55 0 31 5 30 -63 12 -56 -15 -101 -11 -144 13 -41 21 -53 51 -53 128 l0
73 -37 0 c-21 0 -49 3 -61 7 l-24 6 4 -121z"
                />
                <path
                  d="M221 346 l-43 -9 6 -41 c12 -70 79 -196 105 -196 5 0 11 31 15 69 6
57 4 78 -13 118 -11 26 -21 53 -23 58 -2 7 -17 7 -47 1z"
                />
                <path
                  d="M2850 318 c0 -28 50 -117 84 -151 59 -60 109 -80 206 -85 99 -5 163
15 243 73 40 30 47 40 47 70 0 40 -7 43 -43 15 -135 -106 -312 -87 -377 40
-21 41 -40 50 -112 50 -32 0 -48 -4 -48 -12z"
                />
                <path
                  d="M4955 321 l-40 -6 -3 -73 -3 -73 -44 -19 c-32 -14 -45 -25 -45 -40 0
-19 6 -20 150 -20 141 0 150 1 150 19 0 13 -15 28 -42 43 l-43 23 -3 78 c-3
85 2 80 -77 68z"
                />
                <path
                  d="M3570 292 c0 -57 110 -170 192 -198 59 -19 163 -22 223 -6 84 23 179
107 207 183 7 17 2 19 -56 19 l-63 0 -18 -40 c-21 -47 -73 -97 -111 -106 -88
-22 -181 23 -218 107 l-21 47 -68 4 c-50 2 -67 0 -67 -10z"
                />
                <path
                  d="M4470 236 c0 -60 -11 -75 -69 -91 -26 -8 -37 -17 -39 -33 -3 -22 -3
-22 157 -22 152 0 161 1 161 19 0 13 -15 28 -42 43 -42 23 -43 25 -46 81 l-3
57 -59 0 -60 0 0 -54z"
                />
              </g>
            </svg>
          </div>
        </div>
        {/* Nav Menu */}
        <Navbar />
      </div>
      {/* UserSide */}
      <div className="h-full flex items-center justify-end gap-4">
        {/* dark Mode button*/}
        <button onClick={handleChange} className="hidden md:block">
          {theme === "light" ? (
            <FaMoon className="w-6 h-6 text-slate-500 p-1 soft-hover" />
          ) : (
            <BsFillSunFill className="w-6 h-6 text-slate-500 soft-hover" />
          )}
        </button>
        <div className="relative w-8 h-8 rounded-full hidden md:block">
          <FiBell className="w-8 h-8 p-1 text-slate-500 soft-hover rounded-full " />
          <span className="absolute inline-flex items-center justify-center w-6 h-6 text-12 font-medium text-white bg-orange-500 border-2 border-white rounded-full -top-1.5 -right-2 dark:border-gray-900">
            7
          </span>
        </div>
        <div
          className="w-8 h-8 rounded-full p-1 items-center justify-center hidden md:block"
          onClick={() => dispatch(setAvatarMenuOpen())}
        >
          <FaRegUserCircle className="relative w-6 h-6 text-slate-500 soft-hover p-0" />
          <AvatarMenu />
        </div>
        {/* HamburgerMenu */}
        <div>
          {isMobileMenuOpen ? (
            <AiOutlineClose
              className="w-8 h-8 text-slate-500 soft-hover mr-3 block md:hidden"
              onClick={() => dispatch(setMobileMenuOpen())}
            />
          ) : (
            <AiOutlineMenu
              className="w-8 h-8 text-slate-500 soft-hover mr-3 block md:hidden"
              onClick={() => dispatch(setMobileMenuOpen())}
            />
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
