export const boudRate = [
    
    {
        title:"9600",
        value:"9600"
    },
    {
        title:"19200",
        value:"19200"
    },
    {
        title:"38400",
        value:"38400"
    },
    {
        title:"57600",
        value:"57600"
    },
    {
        title:"115200",
        value:"115200"
    }
]