import React, { useEffect, useState } from "react";
import { SlEnergy } from "react-icons/sl";
import { GiReceiveMoney, GiFactory } from "react-icons/gi";
import { CgData } from "react-icons/cg";
import { TbBellRingingFilled } from "react-icons/tb";
import Loading from "../Loading";

const totalData = [
  {
    id: 1,
    title: "Toplam Aktif Enerji",
    energyUnit: "KwH",
    amount: "125.580.590,1250",
    moneyUnit: "TL",
    price: "45.000,25",
  },
  {
    id: 2,
    title: "Toplam Reaktif Enerji",
    energyUnit: "Kvarh",
    amount: "125.580.590,1250",
    moneyUnit: "TL",
    price: "10.000,25",
  },
  {
    id: 3,
    title: "Tesislerim",
    amount: "1.250",
    product: "Cihaz",
    pAmount: "10.250",
  },
  {
    id: 3,
    title: "Online Modemler",
    amount: "1.250",
    product: "Cihaz",
    pAmount: "10.250",
  },
];
const reactiveAlarmsYesterday = [
  {
    id: 1,
    title: "Reactif Alarmlar Dün",
    date: "13.03.2023",
    table_headers: ["Tesis", "Aktif Tük.Kwh", "End.%", "Kap%"],
    data: [
      ["Aktürk Makina esenyurt bölüm 1", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 2", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 3", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 4", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 5", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 6", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 7", "1560.1", "10.5", "20.5"],
    ],
  },
];
const reactiveAlarmsMonth = [
  {
    id: 1,
    title: "Reactif Alarmlar Güncel Ay",
    date1: "01.03.2023",
    date2: "13.03.2023",
    table_headers: ["Tesis", "Aktif Tük.Kwh", "End.%", "Kap%"],
    data: [
      ["Aktürk Makina esenyurt bölüm 1", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 2", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 3", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 4", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 5", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 6", "1560.1", "10.5", "20.5"],
      ["Aktürk Makina esenyurt bölüm 7", "1560.1", "10.5", "20.5"],
    ],
  },
];
const disconnectedModems = [
  {
    id: 1,
    title: "Bağlantısı Olmayan Modemler",
    date: "13.03.2023",
    table_headers: ["Tesis", "Modem NO", "İletişim", "Son Bağlantı"],
    data: [
      ["Aktürk Makina esenyurt bölüm 1", "10230001", "Wifi", "01.01.2023"],
    ],
  },
];


function HomePage() {

  const [isLoading,setIsLoading] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      setIsLoading(false)
    })
  },[isLoading])
  return (
    <>
    {isLoading ? <Loading /> : (
      <section className="flex flex-col pt-16 mx-8">
      {/* Total Cards */}
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 content-center gap-4 mt-8 ml-0 z-0">
        <div className="relative w-full flex items-center justify-center mt-4 px-4 pt-4 pb-2 shadow-lg rounded-md bg-white dark:bg-bgCardDark soft-animation">
          <SlEnergy className="absolute -top-4 left-5 w-16 h-16 p-2 rounded-xl bg-skyCustom text-white shadow-xl" />
          <div className="w-full flex flex-col items-end justify-center mt-8">
            <span className="w-full text-end text-md font-bold tracking-wider text-slate-600 dark:text-white mb-2 border-b-2 dark:border-b-white soft-animation">
              {totalData[0].title}
            </span>
            <div>
              <span className="text-sm px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[0].amount}
              </span>
              <span className="text-sm px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[0].energyUnit}
              </span>
            </div>
            <div>
              <span className="text-lg font-bold px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[0].price}
              </span>
              <span className="text-lg font-bold px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[0].moneyUnit}
              </span>
            </div>
          </div>
        </div>

        <div className="relative w-full flex items-center justify-center mt-4 px-4 pt-4 pb-2 shadow-lg rounded-md bg-white dark:bg-bgCardDark soft-animation">
          <GiReceiveMoney className="absolute -top-4 left-5 w-16 h-16 p-2 rounded-xl bg-fuchsia-500 text-white dark:text-white shadow-xl" />
          <div className="w-full flex flex-col items-end justify-center">
            <span className="w-full text-md text-end font-bold tracking-wider text-slate-600 mb-2 mt-8 border-b-2 dark:text-white dark:border-b-white soft-animation">
              {totalData[1].title}
            </span>
            <div>
              <span className="text-sm px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[1].amount}
              </span>
              <span className="text-sm px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[1].energyUnit}
              </span>
            </div>
            <div>
              <span className="text-lg font-bold px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[1].price}
              </span>
              <span className="text-lg font-bold px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[1].moneyUnit}
              </span>
            </div>
          </div>
        </div>

        <div className="relative w-full flex items-center justify-end mt-4 px-4 pt-4 pb-2 shadow-lg rounded-md bg-white dark:bg-bgCardDark dark:text-white soft-animation">
          <GiFactory className="absolute -top-4 left-5 bg-greenCustom w-16 h-16 p-2 rounded-xl text-white shadow-xl" />

          <div className="w-full flex flex-col items-center justify-center mt-8">
            <div className="w-full flex flex-col items-end justify-center">
              <span className=" w-full text-end text-md px-2 tracking-wide font-bold text-slate-600 border-b-2 dark:border-b-white dark:text-white soft-animation">
                {totalData[2].title}
              </span>
              <span className="text-md px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[2].amount}
              </span>
            </div>
            <div className="w-full flex flex-col items-end justify-center mt-4">
              <span className="w-full text-end text-md px-2 tracking-wide font-bold text-slate-600 border-b-2 dark:border-b-white dark:text-white soft-animation">
                {totalData[2].product}
              </span>
              <span className="text-md px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[2].pAmount}
              </span>
            </div>
          </div>
        </div>

        <div className="relative w-full flex items-center justify-end mt-4 p-4 shadow-lg rounded-xl bg-white dark:bg-bgCardDark dark:text-white soft-animation">
          <CgData className="absolute -top-4 left-5 bg-orangeCustom w-16 h-16 p-2 rounded-xl text-white shadow-xl" />

          <div className="w-full flex flex-col items-center justify-center mt-8">
            <div className="w-full flex flex-col items-end justify-center">
              <span className=" w-full text-end text-md px-2 tracking-wide font-bold text-slate-600 border-b-2 dark:text-white dark:border-b-white soft-animation">
                {totalData[3].title}
              </span>
              <span className="text-md px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[3].amount}
              </span>
            </div>
            <div className="w-full flex flex-col items-end justify-center mt-4">
              <span className="w-full text-end text-md px-2 tracking-wide font-bold text-slate-600 border-b-2 dark:text-white dark:border-b-white soft-animation">
                {totalData[3].product}
              </span>
              <span className="text-md px-2 tracking-wide text-slate-600 dark:text-white soft-animation">
                {totalData[3].pAmount}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 m-0 px-0  z-0">
        {/* Alarm Card */}
        <div className="relative min-w-310 w-full px-2 flex flex-col rounded-md bg-white mt-8 dark:bg-bgCardDark">
          <TbBellRingingFilled className="absolute -top-4 left-5 w-16 h-16 p-2 rounded-xl bg-red-500 text-white shadow-xl" />
          {/* Header */}
          <div className="w-full mt-4 flex items-center justify-between mb-2 ">
            <span className="text-md font-bold tracking-wider text-slate-600 dark:text-white  mb-3 soft-animation mt-8 ">
              {reactiveAlarmsYesterday[0].title}
            </span>
            <span className="text-md font-bold tracking-wider text-slate-600 dark:text-white pl-2 mb-3 mt-8 soft-animation ">
              {reactiveAlarmsYesterday[0].date}
            </span>
          </div>
          {/* Table */}
          <div className="my-2 overflow-x-auto">
            <table className="min-w-[580px] w-full">
              <thead>
                <tr className="text-slate-600 dark:text-white">
                  <th className="border-b border-black dark:border-b-sky-500 text-sm">
                    {reactiveAlarmsYesterday[0].table_headers[0]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 text-sm">
                    {reactiveAlarmsYesterday[0].table_headers[1]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 text-sm">
                    {reactiveAlarmsYesterday[0].table_headers[2]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 text-sm">
                    {reactiveAlarmsYesterday[0].table_headers[3]}
                  </th>
                </tr>
              </thead>
              <tbody>
                {reactiveAlarmsYesterday[0].data.map((item, index) => (
                  <tr
                    key={index}
                    className={`w-full ${
                      index % 2 === 0
                        ? "bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500"
                        : "bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500"
                    } hover:bg-sky-100`}
                  >
                    <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                      {item[0]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[1]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[2]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[3]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Alarm Card */}
        <div className="relative min-w-310 w-full px-2 flex flex-col rounded-md bg-white mt-8 dark:bg-bgCardDark">
          <TbBellRingingFilled className="absolute -top-4 left-5 w-16 h-16 p-2 rounded-xl bg-red-500 text-white shadow-xl" />
          {/* Header */}
          <div className="w-full px-2 mt-4 flex items-center justify-between">
            <span className="w-full text-md font-bold tracking-wider text-slate-600 mb-3 mt-8 dark:text-white">
              {reactiveAlarmsMonth[0].title}
            </span>
            <div className="flex flex-col mt-8">
              <span className="text-md font-bold tracking-wider text-slate-600 dark:text-white">
                {reactiveAlarmsMonth[0].date1}
              </span>
              <span className="text-md font-bold tracking-wider text-slate-600 dark:text-white">
                {reactiveAlarmsMonth[0].date2}
              </span>
            </div>
          </div>
          {/* Table */}
          <div className="my-2 overflow-x-auto">
            <table className="min-w-[580px] w-full">
              <thead>
                <tr>
                  <th className="border-b border-black dark:border-b-sky-500 dark:text-white text-sm">
                    {reactiveAlarmsMonth[0].table_headers[0]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 dark:text-white text-sm">
                    {reactiveAlarmsMonth[0].table_headers[1]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 dark:text-white text-sm">
                    {reactiveAlarmsMonth[0].table_headers[2]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 dark:text-white text-sm">
                    {reactiveAlarmsMonth[0].table_headers[3]}
                  </th>
                </tr>
              </thead>
              <tbody>
                {reactiveAlarmsMonth[0].data.map((item, index) => (
                  <tr
                    key={index}
                    className={`w-full ${
                      index % 2 === 0
                        ? "bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500"
                        : "bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500"
                    } hover:bg-sky-100`}
                  >
                    <td className="py-1.5 text-slate-950 pl-2 dark:text-white text-sm">
                      {item[0]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[1]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[2]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[3]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Alarm Card */}
        <div className="relative min-w-310 w-full px-2 flex flex-col rounded-md bg-white mt-8 dark:bg-bgCardDark">
          <TbBellRingingFilled className="absolute -top-4 left-5 w-16 h-16 p-2 rounded-xl bg-red-500 text-white shadow-xl" />
          {/* Header */}
          <div className="w-full px-4 mt-4 flex items-center justify-between">
            <span className="text-md font-bold tracking-wider text-slate-600 mb-3  mt-8 dark:text-white">
              {disconnectedModems[0].title}
            </span>
            <span className="text-md font-bold tracking-wider text-slate-600 mb-3  mt-8 dark:text-white">
              {disconnectedModems[0].date}
            </span>
          </div>
          {/* Table */}
          <div className="my-2 overflow-x-auto">
            <table className="min-w-[580px] w-full">
              <thead>
                <tr>
                  <th className="border-b border-black dark:border-b-sky-500 dark:text-white text-sm">
                    {disconnectedModems[0].table_headers[0]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 dark:text-white text-sm">
                    {disconnectedModems[0].table_headers[1]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 dark:text-white text-sm">
                    {disconnectedModems[0].table_headers[2]}
                  </th>
                  <th className="border-b border-black text-left dark:border-b-sky-500 dark:text-white text-sm">
                    {disconnectedModems[0].table_headers[3]}
                  </th>
                </tr>
              </thead>
              <tbody>
                {disconnectedModems[0].data.map((item, index) => (
                  <tr
                    key={index}
                    className={`w-full ${
                      index % 2 === 0
                        ? "bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500"
                        : "bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500"
                    } hover:bg-sky-100`}
                  >
                    <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                      {item[0]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[1]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[2]}
                    </td>
                    <td className="py-1.5 text-slate-950 dark:text-white text-sm">
                      {item[3]}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    )}
    </>
  );
}

export default HomePage;
