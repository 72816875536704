import { createSlice } from '@reduxjs/toolkit';
import { fetchReportsCityList } from '../../../../services/reports/filters/city';

export const ReportsFiltersCity = createSlice({
	name: 'ReportsFiltersCity',
	initialState: {
		cityList: null,
		isLoading: false,
		error: null,
	},

	reducers: {},

	extraReducers: (builder) => {
		builder.addCase(fetchReportsCityList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchReportsCityList.fulfilled, (state, action) => {
			const { BaseResponse, cityList } = action.payload;

			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.cityList = cityList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(fetchReportsCityList.rejected, (state, action) => {
			state.error = action.error.message;
			state.isLoading = false;
		});
	},
});

export default ReportsFiltersCity.reducer;
