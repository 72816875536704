import { useState, useEffect, useRef, useMemo } from 'react';

const MultipleSelect = ({
	label,
	options,
	selectedOptions,
	setSelectedOptions,
	handleConfirm,
}) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCount, setSelectedCount] = useState(selectedOptions.length);
	const selectRef = useRef();

	const toggleOption = (option) => {
		setSelectedOptions((prevSelected) =>
			prevSelected.includes(option.value)
				? prevSelected.filter((item) => item !== option.value)
				: [...prevSelected, option.value]
		);
	};

	const isSelected = (option) => selectedOptions.includes(option.value);

	const filteredOptions = useMemo(() => {
		return options.filter((option) =>
			option.title.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [searchTerm, options]);

	useEffect(() => {
		setSelectedCount(selectedOptions.length);
	}, [selectedOptions]);

	useEffect(() => {
		const handleOutsideClick = (e) => {
			if (selectRef.current && !selectRef.current.contains(e.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	const handleButtonClick = () => {
		setIsOpen(false);
		handleConfirm(selectedOptions);
	};

	const handleResetSelectedOptions = () => {
		if (selectedOptions.length >= 1) {
			setSelectedOptions([]);
			handleConfirm([]);
			setIsOpen(false);
		}
	};

	return (
		<div>
			<label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
				{label}
			</label>

			<div
				className="relative mb-6"
				ref={selectRef}
				onFocus={() => setIsOpen(true)}
			>
				<input
					className="p-1 min-w-200 w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:outline-none"
					type="text"
					placeholder={`Ara... (${selectedCount} tesis seçili)`}
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					onFocus={() => setSearchTerm('')}
				/>
				{isOpen && (
					<ul className="absolute w-full mt-2 bg-white border border-gray-300 rounded-md max-h-50 overflow-y-auto dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
						{filteredOptions.length >= 1 ? (
							filteredOptions.map((option) => (
								<li
									key={option.value}
									className={`option cursor-pointer flex items-center ${
										isSelected(option) ? 'bg-blue-100' : ''
									}`}
									onChange={() => toggleOption(option)}
									style={{
										paddingLeft: '10px',
										paddingTop: '4px',
										paddingBottom: '4px',
									}}
								>
									<label
										htmlFor={`option-${option.value}`}
										className="relative flex items-center"
									>
										<input
											id={`option-${option.value}`}
											type="checkbox"
											defaultChecked={false}
											checked={isSelected(option) ? true : false}
											className="form-checkbox h-4 w-4 mr-2 rounded-sm cursor-pointer border border-gray-300"
										/>
										<span
											className={`checkbox-label cursor-pointer ${
												isSelected(option)
													? 'text-blue-700 font-medium'
													: ''
											}`}
										>
											{option.title}
										</span>
									</label>
								</li>
							))
						) : (
							<p
								style={{
									color: 'crimson',
									textAlign: 'center',
									marginTop: '5px',
								}}
							>
								Bir eşleşme bulunamadı
							</p>
						)}

						{filteredOptions.length >= 1 ? (
							<>
								<div className="flex items-center justify-between gap-3 px-2">
									<button
										className={`${
											selectedOptions.length === 0
												? 'bg-red-300'
												: 'bg-red-700'
										} shadow hover:shadow-lg transition-all duration-500 option cursor-pointer p-2 text-center text-white rounded-md mt-2 mb-1 w-full`}
										onClick={handleResetSelectedOptions}
										disabled={selectedOptions.length === 0}
									>
										Temizle
									</button>

									<button
										className="shadow hover:shadow-lg transition-all option cursor-pointer p-2 text-center bg-blue-500 text-white rounded-md mt-2 mb-1 w-full"
										onClick={handleButtonClick}
									>
										Onayla
									</button>
								</div>
							</>
						) : (
							<>
								<button
									className="option cursor-pointer p-2 text-center bg-red-900	text-white rounded-md mt-2 mb-1 w-full"
									onClick={handleButtonClick}
								>
									Kapat
								</button>
							</>
						)}
					</ul>
				)}
			</div>
		</div>
	);
};

export default MultipleSelect;
