import { createSlice } from '@reduxjs/toolkit';
import { fetchReportsDeviceList } from '../../../../services/reports/filters/device';

export const ReportsFiltersDevice = createSlice({
	name: 'ReportsFiltersDevice',
	initialState: {
		ReportsFiltersDeviceList: null,
		isLoading: false,
		error: null,
	},

	reducers: {},

	extraReducers: (builder) => {
		builder.addCase(fetchReportsDeviceList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchReportsDeviceList.fulfilled, (state, action) => {
			const { BaseResponse, ReportsDeviceList } = action.payload;

			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.ReportsFacilityList = ReportsDeviceList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(fetchReportsDeviceList.rejected, (state, action) => {
			state.error = action.error.message;
			state.isLoading = false;
		});
	},
});

export default ReportsFiltersDevice.reducer;
