export const facilityGroups = [
    {
        title:"Grup A",
        value:"A"
    },
    {
        title:"Grup B",
        value:"B"
    },
    {
        title:"Grup C",
        value:"C"
    },
    {
        title:"Grup D",
        value:"D"
    },
    {
        title:"Grup E",
        value:"E"
    }
]