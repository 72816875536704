import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendBaseUrl } from '../../../routes';
import { FilterRoutes } from '../../../routes/reports/filters';

export const fetchReportsDeviceList = createAsyncThunk(
	'fetchReportsDeviceList',
	async (requestData) => {
		const deviceListData = await axios.post(
			`${backendBaseUrl}${FilterRoutes.deviceGet}`,
			requestData,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);

		const data = JSON.stringify(deviceListData.data);
		return JSON.parse(data);
	}
);
