import { FaEdit } from 'react-icons/fa';
import { ColumnFilter } from './ColumnFilter';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openFacilityPageForUpdate } from '../../../store/facilitySlice';
import { toast } from 'react-toastify';

export const COLUMNS = [
	{
		Header: 'Tesis Adı',
		accessor: 'FacilityTitle',
		sortType: 'basic',
		className:
			'text-sm text-start px-2 pt-1 rounded-tl-md border-r border-r-gray-400 dark:border-r-gray-300',
		cell: (info) => info.getValue(),
		Filter: ColumnFilter,
	},
	{
		Header: 'Şehir',
		accessor: 'City',
		sortType: 'basic',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Konum',
		accessor: 'State',
		sortType: 'basic',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Enlem',
		accessor: 'Latitude',
		disableSortBy: true,
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		disableFilters: true,
	},
	{
		Header: 'Boylam',
		accessor: 'Longitude',
		disableSortBy: true,
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		disableFilters: true,
	},
	{
		Header: 'Cihaz Adı',
		accessor: 'DeviceTitle',
		sortType: 'basic',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Cihaz Tipi',
		accessor: 'DeviceTypeTitle',
		sortType: 'basic',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Modbus ID',
		accessor: 'ModbusId',
		sortType: 'basic',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Modem NO',
		accessor: 'ModemKey',
		sortType: 'basic',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Son Veri Zamanı',
		accessor: 'last_data_read',
		className:
			'text-start px-2 pt-1  text-sm border-r border-r-gray-400 dark:border-r-gray-300',
		Filter: ColumnFilter,
	},
	{
		Header: 'Düzenle',
		accessor: 'update',
		disableSortBy: true,
		disableFilters: true,
		Cell: ({ row }) => {
			const dispatch = useDispatch();

			const handleUpdateClick = (event) => {
				event.preventDefault();
				let facilityId = row.original.FacilityId;
				let modemId = row.original.ModemId;
				let deviceId = row.original.DeviceId;

				const facilityIdData = {
					facilityId,
					modemId,
					deviceId,
				};
				if (facilityId) {
					dispatch(
						openFacilityPageForUpdate(
							row.original.FacilityId,
							row.original.DeviceId,
							row.original.ModemId
						)
					);
					localStorage.setItem(
						'editFacilityIds',
						JSON.stringify(facilityIdData)
					);
					window.location.href = '/facilities/facility-edit';
				} else {
					toast.error('Hata! : id bulunamadı...!', {
						position: 'top-center',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					});
				}
			};

			return (
				<Link
					type="button"
					className="w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex gap-2 items-center justify-center"
					onClick={(event) => handleUpdateClick(event)}
					to={`/facilities/facility-edit`}
					// to={`update-facility/${row.original.FacilityId}/${row.original.DeviceId}/${row.original.ModemId}`}
				>
					<FaEdit />
					Güncelle
				</Link>
			);
		},
	},
];
