import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { homeRoute } from "../../routes/localRoutes";

function NotFoundPage() {
  const navigate =useNavigate();

  return (
    <section className="w-full h-screen flex flex-col items-center justify-center bg-rose-50">
      <div className="relative">
        <h4 className="text-50 font-bold tracking-wider drop-shadow-2xl text-indigo-900">
          404
        </h4>
        <div className="absolute top-44 left-10 flex flex-col items-center justify-center">
          <div className="w-full flex gap-2 items-end justify-center">
            <span className="text-3xl font-bold tracking-wider text-indigo-900">
              OOPS!
            </span>
            <span className="text-md text-indigo-900">Sayfa Bulunamadı...</span>
          </div>
          <button className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none  font-medium rounded-full text-sm px-5 py-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 mt-4 shadow-xl tracking-widest flex uppercase"
            onClick={()=> navigate(homeRoute,{ replace: true })}
          >
            <BiArrowBack className="w-6 h-6 text-xl font-bold mr-4" />
            <span>Anasayfa</span>
          </button>
        </div>
      </div>
    </section>
  );
}

export default NotFoundPage;
