import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/logo_tp2.png";
import AntcomLogo from "../../assets/antkom.png";
import LoadingButton from "../../components/LoadingButton";

import { toast } from "react-toastify";
import {  errorReset, setRememberMe } from "../../store/authSlice";
import { fetchLogin } from "../../services/auth";
import { setToggleMenu } from "../../store/menuSlice";

function Login() {
  const { isAuthenticated, error, rememberMeChecked, loading } = useSelector(
    (state) => state.auth
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isClickked, setIsClicked] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    isAuthenticated && navigate("/", { replace: true });
  }, [isAuthenticated, navigate]);

  const handleLogin = (event) => {
    event.preventDefault();
    setIsClicked((provState) => !provState);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      toast.error("Geçersiz bir e-mail adresi girdiniz...!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      if (email && password) {
        dispatch(fetchLogin({ email, password }));
        dispatch(setToggleMenu("/"));
        dispatch(errorReset());
      }
    }
  };

  useEffect(() => {
    error &&
      toast.error(`Hata...: ${error}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [error, isClickked]);

  return (
    <section className="w-full h-screen bg-customImage bg-no-repeat bg-cover flex items-center justify-center">
      <div className="relative min-h-400 min-w-310 rounded-xl bg-gray-400 bg-opacity-75 flex flex-col items-center justify-center shadow-2xl">
        <div className="absolute -top-5 w-[75%] h-20 bg-blue-600 rounded-xl opacity-100 flex items-center justify-center shadow-xl">
          <div className="w-full flex items-center justify-center">
            <img src={Logo} alt="Logo" className="w-12 h-12 mx-2" />
            <img src={AntcomLogo} alt="Logo" className=" h-10 mx-2 mb-1" />
          </div>
        </div>
        <h3 className="uppercase mt-20 text-4xl text-white font-medium tracking-widest">
          Hesap Girişi
        </h3>
        <p className="text-white text-xs mt-2">
          E-posta ve şifreniz ile giriş yapabilirsiniz.
        </p>
        <form
          className="w-full h-full p-8 flex flex-col items-start justify-center"
          onSubmit={handleLogin}
        >
          <div className="w-full my-3">
            <label className="inline-block text-white tracking-wide font-medium mb-1">
              E-posta
            </label>
            <input
              type="email"
              // required
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Lütfen E-mail adresinizi doğru giriniz...!"
                )
              }
              className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 text-sm"
              value={email}
              placeholder="E-mail Adresinizi Yazınız"
              onChange={(e) => {
                setEmail(e.target.value);
                e.target.setCustomValidity("");
              }}
            />
          </div>
          <div className="w-full my-3">
            <label className="inline-block text-white tracking-wide font-medium mb-1">
              Şifreniz
            </label>
            <input
              type="password"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("Lütfen Parolanızı giriniz...!")
              }
              placeholder="Parolanızı Yazınız"
              className="w-full border border-gray-300 px-3 py-2 rounded-lg shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 text-sm"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                e.target.setCustomValidity("");
              }}
            />
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center mb-4">
              <input
                id="default-checkbox"
                type="checkbox"
                checked={rememberMeChecked}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-lg focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={() => dispatch(setRememberMe())}
              />
              <label
                htmlFor="default-checkbox"
                className="ml-2 text-sm font-medium border-gray-400 text-white"
              >
                Beni Hatırla
              </label>
            </div>
            <div>
              <p className="text-sm font-medium mb-2 text-white hover:border-b hover:border-gray-200 cursor-pointer">
                Şifremi unuttum ?
              </p>
            </div>
          </div>
          <div className="w-full mt-4 flex items-center justify-between gap-4">
            <button
              type="button"
              className="min-w-140 w-1/2 text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2 text-center mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              Üyelik Talebi
            </button>
            <div className="w-[140px]">
              {loading ? (
                <LoadingButton />
              ) : (
                <button
                  className="min-w-140 w-1/2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-xl text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  type="submit"
                >
                  Giriş
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Login;
