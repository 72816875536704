const Pagination = ({ currentPage, totalPages, handleChangePage }) => {
	const maxVisiblePages = 5;

	const pages = [];
	const startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
	const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

	for (let i = startPage; i <= endPage; i++) {
		pages.push(i);
	}

	return (
		<nav
			aria-label="Page navigation example"
			className="mt-5 flex items-center justify-center"
		>
			<ul className="flex items-center -space-x-px h-8 text-sm">
				<li>
					<a
						href="#"
						onClick={() => handleChangePage(1)}
						className={`${
							currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
						} flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:text-white`}
					>
						<span className="sr-only"></span>
						<svg
							className="w-2.5 h-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 12 10"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M11 1 7 5 11 9"
							/>

							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M5 1 1 5 5 9"
							/>
						</svg>
					</a>
				</li>

				<li>
					<a
						href="#"
						onClick={() => handleChangePage(currentPage - 1)}
						className={`${
							currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
						} flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:text-white`}
					>
						<span className="sr-only">Previous</span>
						<svg
							className="w-2.5 h-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 6 10"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M5 1 1 5l4 4"
							/>
						</svg>
					</a>
				</li>

				{pages.map((pageNumber) => (
					<li key={pageNumber}>
						<a
							href="#"
							onClick={() => handleChangePage(pageNumber)}
							className={`dark:text-white ${
								currentPage === pageNumber
									? 'z-10'
									: 'hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
							} flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 ${
								currentPage === pageNumber
									? 'bg-blue-50 text-blue-600 dark:text-blue-500 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
									: 'hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
							}`}
						>
							{pageNumber}
						</a>
					</li>
				))}

				<li>
					<a
						href="#"
						onClick={() => handleChangePage(currentPage + 1)}
						className={`${
							currentPage === totalPages
								? 'opacity-50 cursor-not-allowed'
								: ''
						} flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:text-white`}
					>
						<span className="sr-only">Next</span>
						<svg
							className="w-2.5 h-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 6 10"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="m1 9 4-4-4-4"
							/>
						</svg>
					</a>
				</li>

				<li>
					<a
						href="#"
						onClick={() => handleChangePage(totalPages)}
						className={`${
							currentPage === totalPages
								? 'opacity-50 cursor-not-allowed'
								: ''
						} flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:text-white`}
					>
						<span className="sr-only">Next</span>
						<svg
							className="w-2.5 h-2.5"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 12 10"
						>
							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="m1 9 4-4-4-4"
							/>

							<path
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="m7 9 4-4-4-4"
							/>
						</svg>
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Pagination;
