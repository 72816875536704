import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setToggleMenu,
  closeAllMenu,
  setAvatarMenuOpen,
} from "../../../store/menuSlice";
import Logo from "../../../assets/logo_tp2.png";
import { set } from "../../../store/themeSlice";

import { FaRegUserCircle, FaMoon } from "react-icons/fa";
import { FiBell } from "react-icons/fi";
import { BsFillSunFill } from "react-icons/bs";
import AvatarMenu from "../AvatarMenu";

function Navbar() {
  const {
    activeMenu,
    isDropMenuCihazlarOpen,
    isDropMenuTesislerOpen,
    isMobileMenuOpen,
  } = useSelector((state) => state.menu);
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickMenu = (event, currentMenuPath) => {
    event.stopPropagation();

    if (currentMenuPath !== "/facility") {
      navigate(currentMenuPath, { replace: true });
      dispatch(closeAllMenu());
    }
    dispatch(setToggleMenu(currentMenuPath));
  };

  const handleClick = () => {
    dispatch(setToggleMenu("/"));
    navigate("/", { replace: true });
  };

  const handleChange = () => {
    const next = theme === "dark" ? "light" : "dark";
    dispatch(set(next));
  };
  return (
    <nav className="h-full w-full ml-3">
      <div className="h-full hidden md:block">
        <ul className="h-full flex items-center justify-center gap-4 text-slate-500 dark:text-white ">
          <li
            className={`soft-menu ${
              activeMenu === "Ana_Sayfa"
                ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                : "hover:shadow-indigo-300"
            }`}
            onClick={(event) => {
              handleClickMenu(event, "/");
            }}
          >
            Ana Sayfa
          </li>
          <li
            className={`soft-menu ${
              activeMenu === "Tesisler"
                ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                : "hover:shadow-indigo-300"
            }`}
            onClick={(event) => {
              handleClickMenu(event, "/facility");
            }}
          >
            <div className="relative inline-block text-left border-none rounded-full">
              <ul className="inline-flex w-full justify-center  px-1 text-sm">
                Tesisler
                <svg
                  className="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
                {isDropMenuTesislerOpen && (
                  <div className="absolute right-0 z-10 mt-3.5 w-56 origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <li
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100"
                        onClick={(event) =>
                          handleClickMenu(event, "/facilities")
                        }
                      >
                        Tesislerim
                      </li>
                      <li
                        className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100"
                        onClick={(event) =>
                          handleClickMenu(event, "/facilities/facility-add")
                        }
                      >
                        Tesis Ekle
                      </li>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </li>

          <li
            className={`soft-menu ${
              activeMenu === "Raporlar"
                ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                : "hover:shadow-indigo-300"
            }`}
            onClick={(event) => {
              handleClickMenu(event, "/raporlar");
            }}
          >
            Raporlar
          </li>

          {/* <li
            className={`soft-menu ${
              activeMenu === "Cihazlar"
                ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                : "hover:shadow-indigo-300"
            }`}
            onClick={() => dispatch(setToggleMenu("/cihazlar"))}
          >
            <div className="relative inline-block text-left border-none rounded-full">
              <ul className="inline-flex w-full justify-center  px-1 text-sm">
                Cihazlar
                <svg
                  className="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
                {isDropMenuCihazlarOpen && (
                  <div className="absolute right-0 z-10 mt-3.5 w-56 origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <li
                        className="text-gray-700 block px-4 py-2 text-sm"
                        onClick={(event) =>
                          handleClickMenu(event, "/cihaz-ekle")
                        }
                      >
                        Cihaz Ekle
                      </li>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </li> */}
        </ul>
      </div>
      {/* Mobile Responsive Menu */}
      <div
        className={`${
          isMobileMenuOpen &&
          "block md:hidden fixed left-0 top-0 w-full h-screen bg-black/70 dark:bg-black/20"
        }`}
        onClick={() => dispatch(closeAllMenu())}
      >
        {/* Sidebar Menu */}
        <div
          className={
            isMobileMenuOpen
              ? " fixed left-0 top-0 w-[75%] sm:w-[60%] md:w-[45%] min-h-screen h-full bg-[#ecf0f3] pt-4 pb-10 px-4 dark:bg-bgNavDark z-50 ease-in duration-500"
              : "fixed left-[-100%] top-0 min-h-screen p-10 dark:bg-bgNavDark z-50 ease-in duration-500"
          }
        >
          {/* Header */}
          <div className="w-full flex flex-col items-center justify-start">
            {/* Logo Side */}
            <div className="w-full flex items-center justify-between">
              <div
                className="w-full flex items-center mr-6"
                onClick={handleClick}
              >
                <img src={Logo} alt="Logo" className="w-12 ml-2" />
              </div>
              {/* Right side */}
              <div className=" flex items-center justify-end gap-4">
                {/* dark Mode button*/}
                <button onClick={handleChange} className="">
                  {theme === "light" ? (
                    <FaMoon className="w-6 h-6 text-slate-500 p-1 soft-hover" />
                  ) : (
                    <BsFillSunFill className="w-6 h-6 text-slate-500 soft-hover" />
                  )}
                </button>
                <div className="relative w-8 h-8 rounded-full ">
                  <FiBell className="w-8 h-8 p-1 text-slate-500 soft-hover rounded-full " />
                  <span className="absolute inline-flex items-center justify-center w-6 h-6 text-12 font-medium text-white bg-orange-500 border-2 border-white rounded-full -top-1.5 -right-2 dark:border-gray-900">
                    7
                  </span>
                </div>
                <div
                  className="w-8 h-8 rounded-full p-1 items-center justify-center "
                  onClick={() => dispatch(setAvatarMenuOpen())}
                >
                  <FaRegUserCircle className="relative w-6 h-6 text-slate-500 soft-hover p-0" />
                  <AvatarMenu />
                </div>
              </div>
            </div>
          </div>
          {/* Navbar */}
          <ul className="h-full flex-col  flex items-center justify-start mt-20 gap-8 text-slate-500 dark:text-white z-10">
            <li
              className={`soft-menu ${
                activeMenu === "Ana_Sayfa"
                  ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                  : "hover:shadow-indigo-300"
              }`}
              onClick={(event) => {
                handleClickMenu(event, "/");
              }}
            >
              Ana Sayfa
            </li>

            <li
              className={`w-3/4 soft-menu z-50 ${
                activeMenu === "Tesisler"
                  ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                  : "hover:shadow-indigo-300"
              }`}
              onClick={(event) => {
                handleClickMenu(event, "/facility");
              }}
            >
              <div className="relative w-full inline-block text-right border-none rounded-full">
                <ul className="inline-flex w-full justify-center  px-1 text-sm z-30">
                  Tesisler
                  <svg
                    className="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {isDropMenuTesislerOpen && (
                    <div className="absolute right-0 mt-3.5 w-56 origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <li
                          className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 z-20"
                          onClick={(event) =>
                            handleClickMenu(event, "/facilities")
                          }
                        >
                          Tesislerim
                        </li>
                        <li
                          className="text-gray-700 block px-4 py-2 text-sm hover:bg-slate-100 z-20"
                          onClick={(event) =>
                            handleClickMenu(event, "/add-facility")
                          }
                        >
                          Tesis Ekle
                        </li>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </li>

            <li
              className={`w-3/4 text-center soft-menu z-0  ${
                activeMenu === "Raporlar"
                  ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                  : "hover:shadow-indigo-300"
              }`}
              onClick={(event) => {
                handleClickMenu(event, "/raporlar");
              }}
            >
              Raporlar
            </li>

            {/* <li
              className={`w-3/4 soft-menu ${
                activeMenu === "Cihazlar"
                  ? "bg-bgColor dark:bg-bgDark  border border-slate-200  dark:border-slate-700"
                  : "hover:shadow-indigo-300"
              }`}
              onClick={(event) => {
                handleClickMenu(event, "/cihazlar");
              }}
            >
              <div className="w-full relative inline-block text-left border-none rounded-full">
                <ul className="inline-flex w-full justify-center  px-1 text-sm">
                  Cihazlar
                  <svg
                    className="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {isDropMenuCihazlarOpen && (
                    <div className="absolute right-0 z-30 mt-3.5 w-56 origin-top-right rounded-md bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <li
                          className="text-gray-700 text-end block px-4 py-2 text-sm hover:bg-slate-100"
                          onClick={(event) =>
                            handleClickMenu(event, "/cihaz-ekle")
                          }
                        >
                          Cihaz Ekle
                        </li>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
