import React, { useMemo, useState, useEffect } from 'react';
import { COLUMNS } from './columns';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFacilityList } from '../../../services/facility';
import Loading from '../../../pages/Loading';
import { ColumnFilter } from './ColumnFilter';

function Table() {
	const { user } = useSelector((state) => state.auth);
	const { facilityList, isLoading } = useSelector((state) => state.falicity);

	const [searchOpen, setSearchOpen] = useState('');
	const [pageOffset, setPageOffset] = useState(100);
	const [pageShow, setPageShow] = useState(0);

	const dispatch = useDispatch();

	useEffect(() => {
		const facilityListRequest = {
			userId: user.UserId,
			offset: pageOffset,
			page: pageShow,
		};

		dispatch(fetchFacilityList(facilityListRequest));
	}, [pageShow, pageOffset]);

	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => facilityList || [], [facilityList]);

	const defaultColumn = useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: ColumnFilter,
		}),
		[]
	);
	const tableInstance = useTable(
		{
			columns,
			data,
			defaultColumn,
		},
		useFilters,
		useSortBy,
		usePagination
	);

	if (isLoading) {
		return <Loading />;
	}
	const {
		getTableBodyProps,
		getTableProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		gotoPage,
		pageCount,
		setPageSize,
		state,
		prepareRow,
	} = tableInstance;

	const { pageIndex, pageSize } = state;

	return (
		<>
			{facilityList && (
				<table {...getTableProps()} className="min-w-[1024px] w-full">
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr
								{...headerGroup.getHeaderGroupProps()}
								className="text-white tracking-wider bg-sky-500 rounded-t-md"
							>
								{headerGroup.headers.map((column) => (
									<th
										{...column.getHeaderProps(
											column.getSortByToggleProps()
										)}
									>
										<div className="flex items-center justify-center">
											<div>{column.render('Header')}</div>
											<div className=" text-center px-2">
												{column.disableSortBy ? null : (
													<span
														onClick={() =>
															column.isSorted &&
															searchOpen === column.Header
																? null
																: column.getSortByToggleProps()
														}
													>
														{' '}
														{column.isSortedDesc
															? '🔽'
															: '🔼'}{' '}
													</span>
												)}
											</div>
											{column.disableFilters ? null : (
												<span
													className="mx-2"
													onClick={() =>
														setSearchOpen((prevState) =>
															prevState === column.Header
																? ''
																: column.Header
														)
													}
												>
													🔍
												</span>
											)}
										</div>

										{searchOpen === column.Header &&
											!column.disableFilters && (
												<div>
													{column.canFilter
														? column.render('Filter')
														: null}
												</div>
											)}
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()}>
						{page.map((row, index) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={`w-full ${
										index % 2 === 0
											? 'bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500'
											: 'bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500'
									} hover:bg-sky-100`}
								>
									{row.cells.map((cell, index) => {
										return (
											<td
												{...cell.getCellProps()}
												className={`py-1.5 text-slate-950 dark:text-white pl-2 pr-2 text-sm min-h-44 h-[40px] ${
													index === row.cells.length - 1
														? ''
														: 'border-r border-r-gray-400 dark:border-r-gray-300'
												}`}
											>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			)}

			<div className="pagination px-2 text-center">
				<span>
					Page {/* <strong> */}
					{pageIndex + 1} of {pageOptions.length}
					{/* </strong>{' '} */}
				</span>

				<span>
					&nbsp;&nbsp;&nbsp;&nbsp; Go to page:{' '}
					<input
						type="number"
						defaultValue={pageIndex + 1}
						onChange={(e) => {
							const pageNumber = e.target.value
								? Number(e.target.value) - 1
								: 1;
							setPageShow(pageNumber);
							console.log(pageShow);
							gotoPage(pageNumber);
						}}
						className="border border-slate-400 px-1 w-[60px] py-0 text-center"
					/>
				</span>

				<select
					value={pageSize}
					onChange={(e) => {
						setPageSize(Number(e.target.value));
						setPageOffset(Number(e.target.value));
					}}
					className="border border-slate-400"
				>
					{[10, 15, 25, 50, 100].map((pageSize) => (
						<option key={pageSize} value={pageSize}>
							Show {pageSize}
						</option>
					))}
				</select>

				<button
					onClick={() => gotoPage(0)}
					disabled={!canPreviousPage}
					className="w-[40px] bg-indigo-300 mx-1 my-2 rounded-md cursor-pointer hover:bg-indigo-400"
				>
					{'<<'}
				</button>

				<button
					onClick={() => previousPage()}
					disabled={!canPreviousPage}
					className="w-[40px] bg-indigo-300 mr-1 my-2 rounded-md cursor-pointer hover:bg-indigo-400"
				>
					{'<'}
				</button>

				<button
					onClick={() => nextPage()}
					disabled={!canNextPage}
					className="w-[40px] bg-indigo-300 mr-1 my-2 rounded-md cursor-pointer hover:bg-indigo-400"
				>
					{'>'}
				</button>

				<button
					onClick={() => gotoPage(pageCount - 1)}
					disabled={!canNextPage}
					className="w-[40px] bg-indigo-300 mr-1 my-2 rounded-md cursor-pointer hover:bg-indigo-400"
				>
					{'>>'}
				</button>
			</div>
		</>
	);
}

export default Table;
