import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { signOut } from "../../../store/authSlice";
import { useNavigate } from "react-router-dom";

import { GoSignOut } from "react-icons/go";

function AvatarMenu() {
  const { isAvatarMenuOpen } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(signOut());
    navigate("/login", { replace: true });
  };
  //console.log(isAvatarMenuOpen);
  //console.log(user);
  return (
    <div
      className={`${
        isAvatarMenuOpen ? "block" : "hidden"
      } absolute top-8 rounded-md right-5 bg-white mt-2 origin-top-right divide-y divide-gray-100  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none  z-30`}
    >
      <div className="w-full flex flex-col p-2">
        <div className="w-full flex gap-2 font-semibold">
          <span>{user.Name}</span>
          <span>{user.Surname}</span>
        </div>
        <span className="text-xs text-slate-600">{user.Email}</span>
        <span className="text-xs text-slate-600 mt-1">
          {user.Phone.slice(0, 2) +
            " " +
            user.Phone.slice(2, 5) +
            " " +
            user.Phone.slice(5, 8) +
            " " +
            user.Phone.slice(8, 10) +
            " " +
            user.Phone.slice(10)}
        </span>
        <div className="w-full border-b border-b-slate-400 mt-1"></div>
        <button
          className="flex items-center justify-center gap-2 mt-2 hover:cursor-pointer"
          onClick={handleSignOut}
        >
          <GoSignOut className="text-blue-600" />
          <span className="text-ms text-blue-600">Oturumu Kapat</span>
        </button>
      </div>
    </div>
  );
}

export default AvatarMenu;
