import React from 'react';

export const ColumnFilter = ({ column }) => {
	const { filterValue, setFilter } = column;

	return (
		<span>
			<input
				value={filterValue || ''}
				onChange={(e) => setFilter(e.target.value)}
				className="outline-none px-1 rounded-md my-2 text-slate-600 mx-2"
				placeholder="Arama"
			/>
		</span>
	);
};
