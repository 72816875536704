import * as yup from "yup";

let validations = yup.object().shape({
  facilityTitle: yup
      .string()
      .min(3, "Tesis adı minimum 3 karakterden oluşmalıdır.")
      .required("Zorunlu alan, Tesis adını yazınız..."),
      city: yup
      .string()
      .min(2, "Şehir minimum 2 karakterden oluşmalıdır.")
      .required("Zorunlu alan... Bir şehir seçiniz..."),
      state: yup
      .string()
      .min(2, "Konum minimum 2 karakterden oluşmalıdır.")
      .required("Zorunlu alan... Bir konum yazınız..."),
      groupTag: yup
      .string()
      .required("Zorunlu alan... Bir grup seçiniz..."),
      modemKey: yup
      .string()
      .required("Zorunlu alan... Bir modem grubu seçiniz..."),
      latitude:yup
      .number().typeError("Girdiğiniz değer rakamlardan oluşmalıdır..."),
      longitude:yup
      .number().typeError("Girdiğiniz değer rakamlardan oluşmalıdır...")
      
  });
  
  export default validations;