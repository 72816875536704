export const CalendarLocaleType = {
	monday: 'PZT',
	tuesday: 'SAL',
	wednesday: 'ÇAR',
	thursday: 'PER',
	friday: 'CUM',
	saturday: 'CMT',
	sunday: 'PAZ',
	ok: 'Tamam',
};
