import { useState, useRef } from 'react';
import Pagination from './Pagination';
import SearchBar from './SearchBar';
import { RiFileExcelLine } from 'react-icons/ri';
import { BsFiletypePdf, BsGraphUpArrow } from 'react-icons/bs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useDownloadExcel } from 'react-export-table-to-excel';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TableForReports = ({ data }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const filteredData = data.filter((item) =>
		item.Value.toString().includes(searchTerm.toLowerCase())
	);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

	const totalPages = Math.ceil(filteredData.length / itemsPerPage);

	const handleChangePage = (page) => {
		if (page < 1) {
			page = 1;
		} else if (page > totalPages) {
			page = totalPages;
		}
		setCurrentPage(page);
	};

	const handleItemsPerPageChange = (e) => {
		setItemsPerPage(Number(e.target.value));
		setCurrentPage(1);
	};

	const downloadTableAsPDF = () => {
		const tableHeader = ['#', 'Değer', 'Zaman'];

		const tableBody = currentItems.map((item, key) => [
			indexOfFirstItem + key + 1,
			item.Value,
			item.Time,
		]);

		const docDefinition = {
			content: [
				{ text: '', fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
				{
					table: {
						widths: ['*', '*', '*'],
						body: [tableHeader, ...tableBody],
					},
					layout: 'lightHorizontalLines',
				},
			],
		};

		const pdfDoc = pdfMake.createPdf(docDefinition);
		pdfDoc.download('rapor.pdf');
	};

	const tableRef = useRef(null);

	const { onDownload } = useDownloadExcel({
		currentTableRef: tableRef.current,
		filename: 'Rapor',
		sheet: 'Rapor',
	});

	return (
		<>
			<div className="relative overflow-x-auto shadow-md sm:rounded-lg p-2">
				<div className="w-full border-b-2 border-slate-500 dark:border-b-white flex items-center justify-between">
					<div className="flex items-center gap-4">
						<SearchBar
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
						/>

						<div className="flex items-center">
							<label className="mr-2 dark:text-white">Öğe Sayısı:</label>
							<select
								className="border rounded p-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
								value={itemsPerPage}
								onChange={handleItemsPerPageChange}
							>
								<option value={10}>10</option>
								<option value={15}>15</option>
								<option value={25}>25</option>
								<option value={50}>50</option>
								<option value={100}>100</option>
							</select>
						</div>
					</div>

					<div className="flex items-center justify-end">
						<RiFileExcelLine
							className="w-10 h-10 text-white bg-skyCustom rounded-md p-1 m-1 hover:cursor-pointer"
							onClick={onDownload}
						/>
						<BsFiletypePdf
							className="w-10 h-10 text-white bg-pinkCustom rounded-md p-1 m-1 hover:cursor-pointer"
							onClick={downloadTableAsPDF}
						/>
						<BsGraphUpArrow className="w-10 h-10 text-white bg-orangeCustom rounded-md p-1 m-1 hover:cursor-pointer" />
					</div>
				</div>

				<table
					ref={tableRef}
					className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
					id="table"
				>
					<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
						<tr>
							<th scope="col" className="px-6 py-3">
								#
							</th>

							<th scope="col" className="px-6 py-3">
								Değer
							</th>

							<th scope="col" className="px-6 py-3">
								Zaman
							</th>
							{/* <th scope="col" className="px-6 py-3">
								Price
							</th>
							<th scope="col" className="px-6 py-3">
								Action
							</th> */}
						</tr>
					</thead>

					<tbody>
						{currentItems.length === 0 ? (
							<tr className="bg-white dark:bg-gray-800">
								<td
									colSpan="4"
									className="px-6 py-4 text-center text-red-600 dark:text-red-400"
								>
									Eşleşen sonuç bulunamadı
								</td>
							</tr>
						) : (
							currentItems.map((item, key) => (
								<tr
									key={key}
									className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
								>
									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
									>
										{indexOfFirstItem + key + 1}
									</th>

									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
									>
										{item.Value}
									</th>

									<th
										scope="row"
										className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
									>
										{item.Time}
									</th>

									{/* <td className="px-6 py-4">$2999</td>
							<td className="px-6 py-4">
								<a
									href="#"
									className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
								>
									Edit
								</a>
							</td> */}
								</tr>
							))
						)}
					</tbody>
				</table>

				<Pagination
					currentPage={currentPage}
					totalPages={totalPages}
					handleChangePage={handleChangePage}
				/>
			</div>
		</>
	);
};

export default TableForReports;
