import * as yup from "yup";

let validations = yup.object().shape({
  deviceTitle: yup
    .string()
    .required("Zorunlu alan... Bir Cihaz adı yazınız...")
    .min(2, "Cihaz adı minimum 2 karakterden oluşmalıdır."),
  modemKey: yup.string().required("Zorunlu alan... Bir modem grubu seçiniz..."),
  currentRatio: yup
    .number()
    .min(0, "Değer 0 ile 1000 arasında olmalıdır.")
    .max(1000, "Değer 0 ile 1000 arasında olmalıdır.")
    .typeError("Geçerli bir sayı girin.")
    .required("Bu alan zorunludur."),
  modbusId: yup
    .number("Modbus adresi rakam olmalıdır...")
    .required("Zorunlu alan... Modbus adresini seçiniz..."),
  baudRate: yup
    .number("Modbus adresi rakam olmalıdır...")
    .required("Zorunlu alan... Baudrate adresini seçiniz..."),
    deviceTypeTitle: yup
    .string()
    .required("Zorunlu alan... Bir cihaz seçiniz..."),
});

export default validations;
