import { createSlice } from "@reduxjs/toolkit";

export const menuSlice = createSlice({
  name: "theme",
  initialState: {
    menu: [
      { id: 0, title: "Tesisler", subMenu: [] },
      { id: 1, title: "Raporlar", subMenu: [] },
      { id: 2, title: "Cihazlar", subMenu: ["Cihaz Ekle"] },
    ],
    activeMenu: "Login",
    menuPath: "/login",
    isDropMenuTesislerOpen: false,
    isDropMenuCihazlarOpen: false,
    isMobileMenuOpen: false,
    isAvatarMenuOpen: false,
  },
  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    setDropMenuTesislerOpen: (state) => {
      state.isDropMenuTesislerOpen = !state.isDropMenuTesislerOpen;
    },
    setDropMenuCihazlarOpen: (state) => {
      state.isDropMenuCihazlarOpen = !state.isDropMenuCihazlarOpen;
    },
    setToggleMenu: (state, action) => {
      const openMenuPath = action.payload;

      switch (openMenuPath.split("/")[1]) {
        case "facility":
          state.isDropMenuCihazlarOpen = false;
          state.isDropMenuTesislerOpen = true;
          break;
        case "facilities":
          state.isDropMenuCihazlarOpen = false;
          state.isDropMenuTesislerOpen = false;
          state.activeMenu = "Tesisler";
          state.menuPath = openMenuPath;
          break;
        case "add-facility":
          state.isDropMenuCihazlarOpen = false;
          state.isDropMenuTesislerOpen = false;
          state.activeMenu = "Tesisler";
          state.menuPath = openMenuPath;
          break;
        case "raporlar":
          state.isDropMenuCihazlarOpen = false;
          state.isDropMenuTesislerOpen = false;
          state.activeMenu = "Raporlar";
          state.menuPath = openMenuPath;
          break;
        case "":
          state.isDropMenuCihazlarOpen = false;
          state.isDropMenuTesislerOpen = false;
          state.activeMenu = "Ana_Sayfa";
          state.menuPath = "/";
          break;
        default:
      }
    },
    setMobileMenuOpen: (state) => {
      state.isMobileMenuOpen = !state.isMobileMenuOpen;
    },
    setAvatarMenuOpen: (state) => {
      state.isAvatarMenuOpen = !state.isAvatarMenuOpen;
    },

    closeAllMenu: (state) => {
      state.isDropMenuCihazlarOpen = false;
      state.isDropMenuTesislerOpen = false;
      state.isMobileMenuOpen = false;
      state.isAvatarMenuOpen = false;
    },
  },
});

export const {
  setActiveMenu,
  setDropMenuTesislerOpen,
  setDropMenuCihazlarOpen,
  setToggleMenu,
  setMobileMenuOpen,
  setAvatarMenuOpen,
  closeAllMenu,
} = menuSlice.actions;
export default menuSlice.reducer;
