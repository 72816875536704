import { useEffect, useState } from "react";

const TooltypeComponent = ({
  title,
  description,
  distance,
  mousePosX,
  mousePosY,
  showTooltip,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [positionStyle, setPositionStyle] = useState({
    top: mousePosY,
    left: mousePosX,
    transform: "translateY(10%) translateX(5px)",
  });

  const [toolTypeHeight, setToolTypeHeight] = useState(0);
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window;

  useEffect(() => {
    if (showTooltip) {
      setTooltipVisible(showTooltip);
      if (tooltipVisible) {
        const element = document.getElementById("tool-type");
        if (element) {
          setToolTypeHeight(element.clientHeight);
        }
      } else {
        setToolTypeHeight(0);
      }
    }
    if (mousePosX === 0 && mousePosY === 0) {
      setTooltipVisible(false);
    }
    if (mousePosX !== 0 && mousePosY !== 0) {
      handleTooltipPosition(mousePosY, mousePosX);
    }
  }, [tooltipVisible, mousePosY, mousePosX, toolTypeHeight, showTooltip]);

  const handleTooltipPosition = (mousePosY, mousePosX) => {
    if (
      screenWidth - mousePosX < 250 &&
      screenHeight - mousePosY < toolTypeHeight
    ) {
      setPositionStyle({
        top: mousePosY,
        left: mousePosX,
        transform: "translateY(-90%) translateX(-115%)",
      });
    } else if (
      screenWidth - mousePosX < 250 &&
      screenHeight - mousePosY > toolTypeHeight
    ) {
      setPositionStyle({
        top: mousePosY,
        left: mousePosX,
        transform: "translateY(-5%) translateX(-115%)",
      });
    } else if (
      screenWidth - mousePosX > 250 &&
      screenHeight - mousePosY < toolTypeHeight
    ) {
      setPositionStyle({
        top: mousePosY,
        left: mousePosX,
        transform: "translateY(-90%) translateX(5%)",
      });
    } else {
      setPositionStyle({
        top: mousePosY,
        left: mousePosX,
        transform: "translateY(-5%) translateX(5%)",
      });
    }
  };

  const handleShowToolTip = tooltipVisible
    ? "absolute z-50 w-64 top-40 mx-4 bg-white border border-gray-300 rounded-lg text-left px-4 py-2"
    : "block";

  return (
    <div
      className={handleShowToolTip}
      id="tool-type"
      style={{ ...positionStyle }}
    >
      {tooltipVisible && (
        <>
          <div className={"antcom-tool-type-card"}>
            <div
              className={
                "border-b border-gray-300 text-gray-700 text-lg font-bold"
              }
            >
              {title}
            </div>
            <div
              className={
                "h-full text-sm font-normal text-gray-700 tracking-wide"
              }
            >
              {description}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TooltypeComponent;
