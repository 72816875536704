import { Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import logo_tp2 from './assets/logo_tp2.png';
import Login from './pages/Login';
import SharedLayout from './pages/SharedLayout';
import NotFound from './components/NotFound';
import Facility from './pages/Facility';
import ReportPage from './pages/ReportPage';
import AddDevice from './pages/AddDevice';
import AddFacility from './pages/AddFacility';
import FacilityEditPage from './pages/Facility/edit-facility';
import HomePage from './pages/HomePage';

import { Navigate } from 'react-router-dom';

import './App.css';
import {
	AuthLocalRoutes,
	FacilityLocalRoutes,
	ReportLocalRoutes,
	homeRoute,
	notFoundRoute,
} from './routes/localRoutes';
import FacilityAddPage from './pages/Facility/add-facility';

function App() {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<meta charSet="utf-8" />
					<title>Antcom</title>
					<link rel="canonical" href="http://antcom.com.tr" />
					<link rel="png" type="image/png" sizes="16x16" href={logo_tp2} />
				</Helmet>

				<ToastContainer />
				<Toaster />

				<Routes>
					<Route path={homeRoute} element={<SharedLayout />}>
						<Route
							index
							element={
								isAuthenticated ? (
									<HomePage />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={FacilityLocalRoutes.tesislerim}
							element={
								isAuthenticated ? (
									<Facility />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={ReportLocalRoutes.raporlar}
							element={
								isAuthenticated ? (
									<ReportPage />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={FacilityLocalRoutes.tesisEkle}
							element={
								isAuthenticated ? (
									<AddFacility />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={FacilityLocalRoutes.updateFacility}
							element={
								isAuthenticated ? (
									<AddFacility />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={FacilityLocalRoutes.cihazEkle}
							element={
								isAuthenticated ? (
									<AddDevice />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={FacilityLocalRoutes.facilityEdit}
							element={
								isAuthenticated ? (
									<FacilityEditPage />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route
							path={FacilityLocalRoutes.facilityAdd}
							element={
								isAuthenticated ? (
									<FacilityAddPage />
								) : (
									<Navigate to={AuthLocalRoutes.login} />
								)
							}
						/>

						<Route path={AuthLocalRoutes.login} element={<Login />} />
					</Route>

					<Route path={notFoundRoute} element={<NotFound />} />
				</Routes>
			</HelmetProvider>
		</>
	);
}

export default App;
