import { createSlice } from '@reduxjs/toolkit';

const comboboxSlice = createSlice({
	name: 'ReportsPageCombobox',
	initialState: {
		ReportsPageFacilityGroup: [],
		ReportsPageCity: [],
		ReportsPageFacility: [],
		ReportsPageDevices: [],
		ReportsPageDataType: [],
		ReportsPageCurrentlyUnknownData: [],
		ReportsPageReportType: [],
		ReportsPageManagement: [],
		SelectedOptions: [],
	},
	reducers: {
		setReportsPageFacilityGroup: (state, action) => {
			state.ReportsPageFacilityGroup = action.payload;
		},
		setReportsPageCity: (state, action) => {
			state.ReportsPageCity = action.payload;
		},
		setReportsPageFacility: (state, action) => {
			state.ReportsPageFacility = action.payload;
		},
		setReportsPageDevices: (state, action) => {
			state.ReportsPageDevices = action.payload;
		},
		setReportsPageDataTypes: (state, action) => {
			state.ReportsPageDataType = action.payload;
		},
		setReportsPageCurrentlyUnknownData: (state, action) => {
			state.ReportsPageCurrentlyUnknownData = action.payload;
		},
		setReportsPageReportType: (state, action) => {
			state.ReportsPageReportType = action.payload;
		},
		setReportsPageManagement: (state, action) => {
			state.ReportsPageManagement = action.payload;
		},
	},
});

export const {
	setReportsPageFacilityGroup,
	setReportsPageCity,
	setReportsPageFacility,
	setReportsPageDevices,
	setReportsPageDataTypes,
	setReportsPageCurrentlyUnknownData,
	setReportsPageReportType,
	setReportsPageManagement,
} = comboboxSlice.actions;

export default comboboxSlice.reducer;
