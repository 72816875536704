import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import themeSlice from './themeSlice';
import menuSlice from './menuSlice';
import facilitySlice from './facilitySlice';
import tooltypeSlice from './tooltypeSlice';
import ReportsFiltersCity from './reportSlice/filters/city';
import ReportsFiltersFacility from './reportSlice/filters/facility';
import ReportsFiltersDevice from './reportSlice/filters/devices';
import comboboxSlice from './comboboxSlice';
import ReportsFiltersDataType from './reportSlice/filters/dataType';
import ReportsFiltersCurrentlyUnknownData from './reportSlice/filters/tag';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		theme: themeSlice,
		menu: menuSlice,
		falicity: facilitySlice,
		tooltype: tooltypeSlice,
		ReportsFiltersCity: ReportsFiltersCity,
		ReportsFiltersFacility: ReportsFiltersFacility,
		ReportsFiltersDevice: ReportsFiltersDevice,
		ReportsFiltersDataType: ReportsFiltersDataType,
		ReportsFiltersCurrentlyUnknownData: ReportsFiltersCurrentlyUnknownData,
		ReportsPageCombobox: comboboxSlice,
	},
	// middleware: (getDefaultMiddleware) =>
	//   getDefaultMiddleware({
	//     serializableCheck: false,
	//   }),
});
