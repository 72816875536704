import { createSlice } from '@reduxjs/toolkit';
import { fetchReportsDataTypeList } from '../../../../services/reports/filters/dataType';

export const ReportsFiltersDataType = createSlice({
	name: 'ReportsFiltersDataType',
	initialState: {
		ReportsFiltersDataType: null,
		isLoading: false,
		error: null,
	},

	reducers: {},

	extraReducers: (builder) => {
		builder.addCase(fetchReportsDataTypeList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchReportsDataTypeList.fulfilled, (state, action) => {
			const { BaseResponse, ReportsFiltersDataType } = action.payload;

			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.ReportsFiltersDataType = ReportsFiltersDataType;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(fetchReportsDataTypeList.rejected, (state, action) => {
			state.error = action.error.message;
			state.isLoading = false;
		});
	},
});

export default ReportsFiltersDataType.reducer;
