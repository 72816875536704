import React from "react";

function FormDropdown({ labelText, options, isRequiredStar, defaultOption, placeholder, name, labelId, handleChange, handleBlur, errors, touched }) {
  
  return (
    <div>
      {labelText && (
        <label
          htmlFor="countries"
          id={labelId}
          className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
        >
          {labelText}
        </label>
      )}
      <div className="flex items-center">
        <select
          id={labelId}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 customPlaceholder dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={defaultOption || ""}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {placeholder && (
            <option value="" disabled>
              {placeholder}
            </option>
          )}
          <option value=""></option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.title}
            </option>
          ))}
        </select>
        {isRequiredStar && <span className="ml-1 text-orange-400">*</span>}
      </div>
      {errors && touched && (<span className="text-red-500 text-sm">{errors}</span>)}
    </div>
  );
}

export default FormDropdown;