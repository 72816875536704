import React from 'react';
import Header from '../../components/Header';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { closeAllMenu } from '../../store/menuSlice';
import Footer from '../../components/Footer';
import Breadcrumbs from '../../components/Breadcrumbs';
import TooltypeComponent from '../../components/base/tooltype-component';

function SharedLayout() {
	const { isAuthenticated } = useSelector((state) => state.auth);
	const tooltypeData = useSelector((state) => state.tooltype);
	const dispatch = useDispatch();
	return (
		<>
			{isAuthenticated && <Header />}
			<div
				className="min-h-screen h-full z-40 relative"
				onClick={() => dispatch(closeAllMenu())}
			>
				{isAuthenticated && <Breadcrumbs />}
				<Outlet />
			</div>
			{tooltypeData.showTooltip && <TooltypeComponent {...tooltypeData} />}
			{isAuthenticated && <Footer />}
		</>
	);
}

export default SharedLayout;
