import React, { useEffect, useState } from 'react';
import FacilityEditForm from '../../components/facility/form-facility-edit';
import FacilityDeviceEditForm from '../../components/facility/form-device-edit';
import { useSelector, useDispatch } from 'react-redux';
import { getFacilityDetails, getModemAndDevicesService } from '../../services/facility';
import { getDeviceTypesService } from '../../services/facility';
import Loading from '../Loading';
import { toast } from 'react-toastify';
import { addDeviceService, addFacilityAdd } from '../../services/facility';
import TableDeviceList from '../../components/facility/table-device-list';
import { setEditingDeviceData } from '../../store/facilitySlice';

function FacilityEditPage() {
	const facility = useSelector((state) => state.falicity);
	const user = useSelector((state) => state.auth.user);

	const [addNewDevice, setAddNewDevice] = useState(false);
	// const [editingDeviceData, setEditingDeviceData] = useState({});
	let editingDeviceData = {};

	const editFacilityIds = JSON.parse(localStorage.getItem('editFacilityIds'));

	const dispatch = useDispatch();

	let facilityData = facility.facilityData;

	const getData = async (facilityRequest) => {
		await dispatch(getFacilityDetails(facilityRequest));
	};

	const getDeviceTypesServiceData = async (request) => {
		await dispatch(getDeviceTypesService());
	};

	const getModemAndDevicesData = async (request) => {
		await dispatch(getModemAndDevicesService(request));
	};
	const currentModemAndDeviceList = facility.modemAndDeviceList?.filter((device) => {
		if (facility.facilityData?.ModemId == device.ModemId) {
			return device;
		}
	});

	const handleUpdateFacility = async (facilityRequest) => {
		if (facilityRequest) {
			const response = await dispatch(addFacilityAdd(facilityRequest));
			// console.log(response.payload);
			if (response.payload.BaseResponse.Success) {
				toast.success('Tesis Güncellendi...', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});

				const facilityRequest = {
					facilityId: editFacilityIds.facilityId,
					modemId: editFacilityIds.modemId,
					deviceId: editFacilityIds.deviceId,
				};
				await getData(facilityRequest);
			} else {
				toast.error(`Hata: ${response.payload.BaseResponse.ResponseMessage}`, {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
			}
		}
	};

	const handleUpdateDevice = async (deviceRequest) => {
		if (deviceRequest) {
			const response = await dispatch(addDeviceService(deviceRequest));
			if (response.payload.BaseResponse.Success) {
				toast.success('Cihaz Güncellendi...', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
				const facilityRequest = {
					facilityId: editFacilityIds.facilityId,
					modemId: editFacilityIds.modemId,
					deviceId: deviceRequest.deviceId,
				};
				await getData(facilityRequest);
				await getModemAndDevicesData({
					facilityId: editFacilityIds.facilityId,
				});
			} else {
				toast.error(`Hata: ${response.payload.BaseResponse.ResponseMessage}`, {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
			}
		}
	};

	const handleAddNewDevice = async (newDeviceRequest) => {
		if (newDeviceRequest) {
			const response = await dispatch(addDeviceService(newDeviceRequest));
			if (response.payload.BaseResponse.Success) {
				toast.success('Cihaz Eklendi...', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
				const facilityRequest = {
					facilityId: editFacilityIds.facilityId,
					modemId: editFacilityIds.modemId,
					deviceId: editFacilityIds.deviceId,
				};
				await getData(facilityRequest);
				await getModemAndDevicesData({
					facilityId: editFacilityIds.facilityId,
				});
			} else {
				toast.error(`Hata: ${response.payload.BaseResponse.ResponseMessage}`, {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
			}
		}
	};

	const handleSetEditingDeviceData = (data) => {
		dispatch(setEditingDeviceData(data));
	};

	useEffect(() => {
		const fetchData = async () => {
			let facilityRequest = {};
			console.log(editFacilityIds);
			if (editFacilityIds.deviceId) {
				facilityRequest = {
					facilityId: editFacilityIds.facilityId,
					modemId: editFacilityIds.modemId,
					deviceId: editFacilityIds.deviceId,
				};
			} else {
				facilityRequest = {
					facilityId: editFacilityIds.facilityId,
					modemId: editFacilityIds.modemId,
					// deviceId: editFacilityIds.deviceId,
				};
			}

			if (editFacilityIds.facilityId && editFacilityIds.modemId) {
				//dispatch(setIsUpdate(true));
				await Promise.all([
					getData(facilityRequest),
					getDeviceTypesServiceData(),
					getModemAndDevicesData({ facilityId: editFacilityIds.facilityId }),
				]);
			}
		};

		fetchData();
	}, []);
	console.log(
		!facility.isLoading,
		editFacilityIds.facilityId,
		facility.facilityData,
		facility.deviceTypes
	);

	if (
		facility.isLoading &&
		!editFacilityIds.facilityId &&
		!facility.facilityData &&
		!facility.deviceTypes
	) {
		return <Loading />;
	}

	return (
		<>
			{!facility.isLoading &&
				editFacilityIds.facilityId &&
				facility.facilityData &&
				facility.deviceTypes && (
					<section className="pt-16 mx-8  min-h-screen">
						{/* Card */}
						<div className="min-w-310 shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8">
							<h3 className="mx-4 pt-4 w-full text-center text-slate-600 dark:text-white tracking-wider text-lg font-medium">
								Tesis Güncelle
							</h3>
							{/* Form */}
							<div>
								<FacilityEditForm
									editFacilityData={facilityData}
									editFacilityIds={editFacilityIds}
									handleUpdateFacility={handleUpdateFacility}
									user={user}
								/>

								<div className="w-full px-4">
									<div className="border-b border-b-slate-300"></div>
								</div>
								{/* Device Add */}
								<FacilityDeviceEditForm
									editFacilityData={facilityData}
									editDeviceData={facility.editingDeviceData}
									deviceTypeList={facility.deviceTypes}
									handleUpdateDevice={handleUpdateDevice}
									handleAddNewDevice={handleAddNewDevice}
									addNewDevice={addNewDevice}
									setAddNewDevice={setAddNewDevice}
								/>
							</div>
						</div>
						{/* Table */}
						<TableDeviceList
							currentModemAndDeviceList={currentModemAndDeviceList}
							handleSetEditingDeviceData={handleSetEditingDeviceData}
							facility={facility}
						/>
					</section>
				)}
		</>
	);
}

export default FacilityEditPage;
