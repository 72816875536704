import React, { useEffect } from 'react';
import FormDropdown from '../../components/base/form-dropdown';
import FormInput from '../../components/base/form-input';
import { boudRate } from '../../mockData/boadRate';
import { useFormik } from 'formik';
import validationSchema from '../validations/form-edit_device';

function FacilityDeviceEditForm({
	editFacilityData,
	editDeviceData,
	deviceTypeList,
	handleUpdateDevice,
	handleAddNewDevice,
	addNewDevice,
	setAddNewDevice,
}) {
	// console.log("editDeviceData",editDeviceData);

	const newDeviceTypeTitle = editDeviceData.DeviceTypeTitle;

	const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues } =
		useFormik({
			initialValues: {
				deviceTitle: editDeviceData?.DeviceTitle || undefined,
				modemKey: editDeviceData?.ModemKey || undefined,
				deviceTypeTitle: newDeviceTypeTitle || '',
				modbusId: editDeviceData?.ModbusId || '',
				baudRate: editDeviceData?.BaudRate || '',
				currentRatio: editDeviceData?.CurrentRatio || undefined,
			},
			onSubmit: async (values) => {
				if (!addNewDevice) {
					//burada güncelleme yapacak
					const deviceRequest = {
						deviceId: editDeviceData.DeviceId,
						modemKey: values.modemKey,
						deviceTitle: values.deviceTitle,
						deviceTypeId: deviceTypeList.find(
							(deviceType) => deviceType.Title === values.deviceTypeTitle
						).Id,
						modbusId: values.modbusId,
						baudRate: values.baudRate,
						currentRatio: values.currentRatio,
					};

					handleUpdateDevice(deviceRequest);
				} else {
					//burada ekleme yapacak
					const newDeviceRequest = {
						modemKey: values.modemKey,
						deviceTitle: values.deviceTitle,
						deviceTypeId: deviceTypeList.find(
							(deviceType) => deviceType.Title === values.deviceTypeTitle
						).Id,
						modbusId: values.modbusId,
						baudRate: values.baudRate,
						currentRatio: values.currentRatio,
					};

					handleAddNewDevice(newDeviceRequest);
					setAddNewDevice(false);
				}
			},
			validationSchema,
		});

	const deviceTypes = deviceTypeList.map((type) => {
		return {
			title: type.Title,
			value: type.Title,
		};
	});

	const modBusAdressList = () => {
		const data = [];
		for (let i = 1; i <= 255; i++) {
			data.push({
				title: i.toString(),
				value: i.toString(),
			});
		}
		return data;
	};

	const handleClickChange = () => {
		setAddNewDevice((prevState) => !prevState);
		handleChange('deviceTitle')(''); // Değerleri sıfırlamak için boş bir dize gönderiyoruz
		handleChange('deviceTypeTitle')('');
		handleChange('currentRatio')('');
		handleChange('baudRate')('');
		handleChange('modbusId')('');
	};

	useEffect(() => {
		if (!editDeviceData.DeviceId) {
			setAddNewDevice(true);
		}
	}, []);

	useEffect(() => {
		console.log('addNewDevice', addNewDevice);

		if (addNewDevice) {
			setValues({
				...values,
				deviceTitle: '',
				deviceTypeTitle: '',
				currentRatio: '',
				baudRate: '',
				modbusId: '',
			});
		} else {
			setValues({
				...values,
				deviceTitle: editDeviceData?.DeviceTitle,
				modemKey: editDeviceData?.ModemKey,
				deviceTypeTitle: newDeviceTypeTitle,
				modbusId: editDeviceData?.ModbusId,
				baudRate: editDeviceData?.BaudRate,
				currentRatio: editDeviceData?.CurrentRatio,
			});
		}
	}, [addNewDevice, editDeviceData]);

	console.log(editDeviceData);
	return (
		<form
			className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 mt-4"
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit();
			}}
		>
			<FormInput
				labelText={'Cihaz Adı'}
				labelId={'device-title'}
				inputPlaceholder={'Cihaz Adını Yazınız'}
				value={values.deviceTitle}
				handleChange={handleChange}
				isRequiredStar={true}
				handleBlur={handleBlur}
				name="deviceTitle"
				errors={errors.deviceTitle}
				touched={touched.deviceTitle}
			/>

			<FormInput
				labelText={'Modem Kodu'}
				labelId={'modem-key'}
				inputPlaceholder={'Modem kodunu yazınız'}
				value={values.modemKey}
				handleChange={handleChange}
				isRequiredStar={true}
				handleBlur={handleBlur}
				name="modemKey"
				errors={errors.modemKey}
				touched={touched.modemKey}
			/>

			<div>
				<FormDropdown
					labelText={'Cihaz'}
					options={deviceTypes}
					defaultOption={values.deviceTypeTitle}
					isRequiredStar={true}
					placeholder={'Bir cihaz seçiniz'}
					name={'deviceTypeTitle'}
					labelId={'facility-group'}
					handleChange={handleChange}
					handleBlur={handleBlur}
					errors={errors.deviceTypeTitle}
					touched={touched.deviceTypeTitle}
				/>
			</div>

			<div>
				<FormDropdown
					labelText={'Modbus Adresi'}
					options={modBusAdressList()}
					defaultOption={values.modbusId}
					isRequiredStart={true}
					placeholder={'Modbus adresini seçiniz'}
					name={'modbusId'}
					labelId={'modbus'}
					handleChange={handleChange}
					handleBlur={handleBlur}
					errors={errors.modbusId}
					touched={touched.modbusId}
				/>
			</div>

			<div className="w-full">
				<FormDropdown
					labelText={'Baud Rate'}
					options={boudRate}
					defaultOption={values.baudRate}
					isRequiredStart={true}
					placeholder={'Baud rate seçiniz'}
					name={'baudRate'}
					labelId={'baud-rate'}
					handleChange={handleChange}
					handleBlur={handleBlur}
					errors={errors.baudRate}
					touched={touched.baudRate}
				/>
			</div>

			<div className="">
				<FormInput
					labelText={'Akım Trafo Oranı'}
					labelId={'current-ratio'}
					inputPlaceholder={'Akım trafosu oranını yazınız'}
					value={values.currentRatio}
					handleChange={handleChange}
					isRequiredStar={true}
					handleBlur={handleBlur}
					name="currentRatio"
					errors={errors.currentRatio}
					touched={touched.currentRatio}
				/>
			</div>

			<div>
				<p className="w-full px-4 text-xs text-slate-600 dark:text-white">
					<span className="text-orange-400 text-lg">*</span> ile belirtilen
					alanlar doldurulması zorunludur...!
				</p>
				<div className="px-4 my-2">
					<label className="relative inline-flex items-center mr-5 cursor-pointer">
						<input
							type="checkbox"
							className="sr-only peer"
							checked={addNewDevice}
							onChange={handleClickChange}
						/>
						<div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-yellow-300 dark:peer-focus:ring-yellow-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-yellow-400"></div>
						<span
							className={`ml-3 text-sm font-medium ${
								editFacilityData.FacilityId
									? 'text-gray-900 dark:text-gray-300'
									: 'text-gray-500 dark:text-gray-200'
							}`}
						>
							Yeni Cihaz Ekle
						</span>
					</label>
				</div>
			</div>

			<div className="w-full px-4 flex justify-center">
				<button
					type="submit"
					className="w-1/2 mb-6 mt-4 border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline disabled:bg-slate-300 disabled:border-slate-300"
					disabled={!editFacilityData?.FacilityId}
				>
					{addNewDevice ? 'Cihaz Ekle' : 'Cihaz Güncelle'}
				</button>
			</div>
		</form>
	);
}

export default FacilityDeviceEditForm;
