import React from "react";

function Footer() {
  return (
    <footer className="w-full bg-slate-100 dark:bg-gray-900 mt-2 z-0 border-t border-t-slate-200 shadow-inner">
      <div className="w-full mx-auto p-4 md:py-8">
        <div className="w-full sm:flex sm:items-center sm:justify-between">
          <a
            href="https://antcom.com.tr/"
            className="flex items-center mb-4 sm:mb-0"
          >
            <img
              src={require("../../assets/logo_tp2.png")}
              className="h-8 mr-3"
              alt="Antcom Logo"
            />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">
              Antcom
            </span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            <li>
              <a href="/" className="mr-4 hover:underline md:mr-6 ">
                Hakkımızda
              </a>
            </li>
            <li>
              <a href="/" className="mr-4 hover:underline md:mr-6">
                Gizlilik Politikası
              </a>
            </li>
            <li>
              <a href="/" className="hover:underline">
                İletişim
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a href="https://www.antkom.com.tr" className="hover:underline">
            Antkom™
          </a>
          . Her hakkı saklıdır.
        </span>
      </div>
    </footer>
  );
}

export default Footer;
