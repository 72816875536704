export const SelectionOptions = {
	branchGroups: [
		{ title: 'Grup A', value: 'A' },
		{ title: 'Grup B', value: 'B' },
		{ title: 'Grup C', value: 'C' },
		{ title: 'Grup D', value: 'D' },
		{ title: 'Grup E', value: 'E' },
	],

	dataTypes: [
		{ title: 'Saatlik', value: 1 },
		{ title: 'Günlük', value: 2 },
	],

	graphicDesign: [
		{ title: 'Tablo', value: 'tablo' },
		{ title: 'Grafik', value: 'grafik' },
	],
};
