import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendBaseUrl } from '../routes';
import { AuthRoutes } from '../routes/auth';

export const fetchLogin = createAsyncThunk('user/auth', async (userData) => {
	const authData = await axios.post(
		`${backendBaseUrl}${AuthRoutes.login}`,
		{ ...userData },
		{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
	);
	// const data = JSON.stringify(authData.data);
	// return JSON.parse(data);
	return authData.data;
});
