import React, { useEffect } from "react";
import { MdLocationOn } from "react-icons/md";
import FormInput from "../../components/base/form-input";
import FormDropdown from "../../components/base/form-dropdown";
import { cities } from "../../mockData/city";
import { facilityGroups } from "../../mockData/facilityGroups";
import { useFormik } from "formik";
import validationSchema from "../../components/validations/form-edit_facility";
import { useDispatch, useSelector } from "react-redux";
import  InfoIcon  from "../../components/base/icons/info-icon";
import { addFacilityAdd } from "../../services/facility";
import { toast } from "react-toastify";


function AddFacilityForm({ facilidyData, isUpdate, facilityId }) {
  const user = useSelector((state) => state.auth.user);
  const facility = useSelector((state) => state.falicity);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      facilityTitle:
        facilidyData && isUpdate ? facilidyData?.FacilityTitle : "",
      city: facilidyData && isUpdate ? facilidyData?.City : "",
      state: facilidyData && isUpdate ? facilidyData?.State : "",
      latitude: facilidyData && isUpdate ? facilidyData?.Latitude : "",
      longitude: facilidyData && isUpdate ? facilidyData?.Longitude : "",
      groupTag: facilidyData && isUpdate ? facilidyData?.GroupTag : "",
      modemKey: facilidyData && isUpdate ? facilidyData?.ModemKey : "",
    },
    onSubmit: async (values) => {
      if (!values.latitude) {
        values.latitude = "41.0197";
      }
      if (!values.longitude) {
        values.longitude = "28.9261";
      }
      console.log("buraya geliyor mu");
      if (facilityId) {
        const facilityRequest = {
          userId: user.UserId,
          facilityId: facilityId,
          facilityTitle: values.facilityTitle,
          city: values.city,
          state: values.state,
          latitude: values.latitude,
          longitude: values.longitude,
          groupTag: values.groupTag,
          modemKey: values.modemKey,
        };
        console.log("atıyorum....!");
        await dispatch(addFacilityAdd(facilityRequest));
        console.log("atıldı geldi");
        if (facility.facilityResponse.Success) {
          toast.success("Tesis Güncellendi...", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        const facilityRequest = {
          userId: user.UserId,
          facilityTitle: values.facilityTitle,
          city: values.city,
          state: values.state,
          latitude: values.latitude,
          longitude: values.longitude,
          groupTag: values.groupTag,
          modemKey: values.modemKey,
        };
        await dispatch(addFacilityAdd(facilityRequest));
        if (facility.facilityResponse.Success) {
          toast.success("Tesis Eklendi...", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    },
    validationSchema,
  });

  const TurkeyCities = cities.map((city) => {
    return {
      title: city.value,
      value: city.value,
    };
  });
  const facility_groups = facilityGroups.map((group) => {
    return {
      title: group.title,
      value: group.value,
    };
  });

  useEffect(() => {
    if (facility.facilityResponse) {
      console.log(facility.facilityResponse);
    }
  }, [facility.facilityResponse]);

  useEffect(() => {
    setValues({
      ...values,
    });
  }, [facilidyData]);

  useEffect(() => {
    facility.error &&
      toast.error(`Hata...: ${facility.error}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [facility.error]);

  console.log("---->",facility)
  return (
    <>
      <form
        className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 mt-4"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
          console.log(values);
        }}
      >
        <FormInput
          labelText={"Tesis Adı"}
          labelId={"facility-title"}
          inputPlaceholder={"Tesis Adını Yazınız"}
          value={values.facilityTitle}
          handleChange={handleChange}
          isRequiredStar={true}
          handleBlur={handleBlur}
          name="facilityTitle"
          errors={errors.facilityTitle}
          touched={touched.facilityTitle}
        />

        <FormDropdown
          labelText={"Şehir"}
          options={TurkeyCities}
          isRequiredStart={true}
          placeholder={"Şehir Seçiniz"}
          defaultOption={values.city}
          name={"city"}
          labelId={"city"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors.city}
          touched={touched.city}
        />

        <FormInput
          labelText={"Adres"}
          labelId={"state"}
          name={"state"}
          inputPlaceholder={"Adresi giriniz"}
          value={values.state}
          handleChange={handleChange}
          isRequiredStar={false}
          errors={errors.state}
          touched={touched.state}
        />

        <FormDropdown
          labelText={"Tesis Grup"}
          options={facility_groups}
          defaultOption={values.groupTag}
          isRequiredStart={true}
          placeholder={"Tesis grubu seçiniz"}
          name={"groupTag"}
          labelId={"facility-group"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          errors={errors.groupTag}
          touched={touched.groupTag}
        />

        <FormInput
          labelText={"Modem Kodu"}
          labelId={"modem-key"}
          inputPlaceholder={"Modem kodunu yazınız"}
          value={values.modemKey}
          handleChange={handleChange}
          isRequiredStar={true}
          handleBlur={handleBlur}
          name="modemKey"
          errors={errors.modemKey}
          touched={touched.modemKey}
        />

        <div className="">
          <label
            htmlFor="countries"
            className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
          >
            Konum
          </label>
          <div className="flex">
            <div className=" w-10 h-10 bg-slate-300 hover:bg-slate-400 flex items-center justify-center rounded-l-md transition duration-500 ease">
              <MdLocationOn className="w-8 h-8 text-slate-700" />
            </div>
            <span className="w-full h-10 border border-slate-300 bg-white flex items-center px-2 rounded-r-md bg-gray-50  dark:bg-[#374151] dark:border-gray-600">
              <div className="flex items-center">
                <div>
                  <input
                    type="text"
                    id="latitude"
                    className="w-1/2 dark:text-white text-gray-900 text-sm focus:outline-none"
                    placeholder="Enlem giriniz"
                    value={values.latitude}
                    onChange={handleChange}
                  />
                  <input
                    type="text"
                    id="longitude"
                    className="w-1/2 dark:text-white text-gray-900 text-sm border-l pl-1 focus:outline-none"
                    placeholder="Boylam giriniz"
                    value={values.longitude}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </span>
            <div className="ml-1 h-full my-auto">
              <div className="relative">
              <InfoIcon />
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="w-full flex items-center justify-center">
          <button
            type="submit"
            className="w-1/2 mb-6 mt-4 border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline"
          >
            Tesis Kayıt
          </button>
        </div>
      </form>
    </>
  );
}

export default AddFacilityForm;
