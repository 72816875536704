import { fetchReportsCityList } from '../services/reports/filters/city';
import { fetchReportsDataTypeList } from '../services/reports/filters/dataType';
import { fetchReportsDeviceList } from '../services/reports/filters/device';
import { fetchReportsFacilityList } from '../services/reports/filters/facility';
import { fetchCurrentlyUnknownData } from '../services/reports/filters/tag';
import {
	setReportsPageCity,
	setReportsPageCurrentlyUnknownData,
	setReportsPageDataTypes,
	setReportsPageDevices,
	setReportsPageFacility,
} from '../store/comboboxSlice';

export const fetchDataAndMapToList = async (
	dispatch,
	fetchDataFn,
	mapFn,
	setListFn,
	requestData,
	mapProperty
) => {
	try {
		const data = await dispatch(fetchDataFn(requestData));
		const payloadList = mapProperty ? data.payload[mapProperty] : data.payload;
		const mappedList = payloadList?.map(mapFn);

		dispatch(setListFn(mappedList));
	} catch (error) {
		// console.log(error);
	}
};

export const fetchListConfigs = [
	{
		fetchDataFn: fetchReportsCityList,
		mapFn: (data) => ({ title: data.cityName, value: data.cityId }),
		setListFn: setReportsPageCity,
		mapProperty: 'cityList',
	},

	{
		fetchDataFn: fetchReportsFacilityList,
		mapFn: (data) => ({ title: data.FacilityTitle, value: data.FacilityId }),
		setListFn: setReportsPageFacility,
		mapProperty: 'FacilityList',
	},

	{
		fetchDataFn: fetchReportsDeviceList,
		mapFn: (data) => ({
			title: data.ProductTypeId,
			value: data.Id,
			ModemId: data.ModemId,
			ModbusId: data.ModbusId,
		}),
		setListFn: setReportsPageDevices,
		mapProperty: 'ProductList',
	},

	{
		fetchDataFn: fetchReportsDataTypeList,
		mapFn: (data) => ({
			title: data.title,
			value: data.id,
			isHourlyDataAvailable: data.isHourlyDataAvailable,
		}),
		setListFn: setReportsPageDataTypes,
		mapProperty: 'DataTypeList',
	},

	{
		fetchDataFn: fetchCurrentlyUnknownData,
		mapFn: (data) => ({
			title: data.title,
			value: data.id,
			dataTypeId: data.dataTypeId,
		}),
		setListFn: setReportsPageCurrentlyUnknownData,
		mapProperty: 'DataTypeList',
	},
];

export const findApiConfig = (fetchDataFn) => {
	const apiConfig = fetchListConfigs.find((item) => item.fetchDataFn === fetchDataFn);
	if (!apiConfig) {
		throw new Error('API ayarları bulunamadı');
	}

	return apiConfig;
};
