import { useEffect, useState } from 'react';
import { BiChevronsLeft, BiChevronsRight } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import ListBox from '../../components/SearchInputComponents/Listbox';
import ChartGraph from '../../components/ChartGraph';
import { fetchReportsFacilityList } from '../../services/reports/filters/facility';
import MultipleSelect from '../../components/Global/MultipleSelect';
import {
	fetchDataAndMapToList,
	fetchListConfigs,
	findApiConfig,
} from '../../Utilities/fetchHelpers';
import { fetchReportsDeviceList } from '../../services/reports/filters/device';
import TableForReports from '../../components/reports/TableForReports';
import axios from 'axios';
import { backendBaseUrl } from '../../routes';
import { FilterRoutes } from '../../routes/reports/filters';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';

import 'rsuite/dist/rsuite.css';
import './custom-date-range-picker.css'; // Özel stilleri içe aktar
import { PredefinedRanges } from '../../components/reports/DateRange/PredefinedRanges';
import { CalendarLocaleType } from '../../components/reports/DateRange/CalendarLocaleType';
import { SelectionOptions } from '../../components/reports/DefaultOptions/SelectionOptions';

const ReportPage = () => {
	const { afterToday } = DateRangePicker;

	const [isOpen, setIsOpen] = useState(false);

	// Çoklu tesis seçmek için
	const [selectedOptions, setSelectedOptions] = useState([]);

	// şehir için
	const [selectedCity, setSelectedCity] = useState(Number(1)); // Default olarak Adana tesislerini listeliyoruz

	// bir grup seçilimi değilmi
	const [selectedGroup, setSelectedGroup] = useState('');

	// Tablomu grafik mi belirtmek için
	const [selectedGraphicDesign, setSelectedGraphicDesign] = useState('tablo');

	// raport tipi seçip seçilen rapor tipine göre veri ve data tipini filtrelemek için
	const [selectedDataType, setSelectedDataType] = useState(null);

	// Cihazlar için
	const [selectedDevice, setSelectedDevice] = useState(null);

	// Data tiplerini comboboxta listelemek için
	const [filteredData, setFilteredData] = useState([]);

	// seçilen raport tip içerisinden data tipi seçmek için
	const [selectedFilteredData, setSelectedFilteredData] = useState(0);

	// HourlyData tablosunda çekilen value ve timeleri tabloda bastırmak için
	const [binaryData, setBinaryData] = useState([]);

	// zaman aralığı seçmek için
	const [selectedRange, setSelectedRange] = useState([
		subDays(new Date(), 7),
		new Date(),
	]);

	const mappedReportsCityList = useSelector(
		(state) => state.ReportsPageCombobox.ReportsPageCity
	);
	const mappedReportsFacilityList = useSelector(
		(state) => state.ReportsPageCombobox.ReportsPageFacility
	);
	const mappedReportsDeviceList = useSelector(
		(state) => state.ReportsPageCombobox.ReportsPageDevices
	);
	const mappedReportsDataTypes = useSelector(
		(state) => state.ReportsPageCombobox.ReportsPageDataType
	);
	const mappedCurrentlyUnknownData = useSelector(
		(state) => state.ReportsPageCombobox.ReportsPageCurrentlyUnknownData
	);
	const user = useSelector((state) => state.auth.user);

	const requestData = {
		userId: user.UserId,
	};

	const resetDevices = (requestData) => {
		const apiConfigDevice = findApiConfig(fetchReportsDeviceList);
		const getFetchReportsDeviceList = async (requestData) => {
			await fetchDataAndMapToList(
				dispatch,
				apiConfigDevice.fetchDataFn,
				apiConfigDevice.mapFn,
				apiConfigDevice.setListFn,
				requestData,
				apiConfigDevice.mapProperty
			);
		};

		getFetchReportsDeviceList(requestData);
	};

	const toggleSidebar = () => {
		setIsOpen(!isOpen);
	};

	const dispatch = useDispatch();

	const handleConfirmForMultipleFacility = (selectedOptions) => {
		requestData.facilities = selectedOptions;
		resetDevices(requestData);
	};

	function handleCityChange(event) {
		setSelectedCity(event.target.value);
		setSelectedOptions([]);
		resetDevices(requestData);
	}

	function handleGroupChange(event) {
		setSelectedGroup(event.target.value);
	}

	function handleGraphicDesign(event) {
		setSelectedGraphicDesign(event.target.value);
	}

	// Bu fonksiyon cihazlar değişince çalışır
	// Localhost'ta calısması için Number ekle hostta calısması için Numberi sil
	const handleSelectedDevice = (event) => {
		setSelectedDevice(
			mappedReportsDeviceList.find((x) => x.value === event.target.value)
		);
	};

	// Bu fonksiyon rapor tipi değişince çalışır
	// Localhost'ta calısması için Number ekle hostta calısması için Numberi sil
	const handleSetDataType = (event) => {
		setSelectedDataType(
			mappedReportsDataTypes.find((x) => x.value === event.target.value)
		);

		// bu kod rapor tipi değişince data tipi 0'dan baslatıyor
		setSelectedFilteredData(0);
	};

	// rapor tipi seçtikten sorna filtrelenen data tipinden veri bulmak için
	// Localhost'ta calısması için Number ekle hostta calısması için Numberi sil
	function handleSelectedDataType(event) {
		// setSelectedFilteredData(filteredData.find((x) => x.value === event.target.value));
		setSelectedFilteredData(
			mappedCurrentlyUnknownData.find((x) => x.value === event.target.value)
		);
	}

	useEffect(() => {
		fetchListConfigs.forEach(({ fetchDataFn, mapFn, setListFn, mapProperty }) => {
			fetchDataAndMapToList(
				dispatch,
				fetchDataFn,
				mapFn,
				setListFn,
				requestData,
				mapProperty
			);
		});
	}, [dispatch]);

	useEffect(() => {
		requestData.cityId = selectedCity;
		requestData.groupTag = selectedGroup;

		const apiConfigFacility = findApiConfig(fetchReportsFacilityList);

		const getFetchReportsFacilityList = async (requestData) => {
			await fetchDataAndMapToList(
				dispatch,
				apiConfigFacility.fetchDataFn,
				apiConfigFacility.mapFn,
				apiConfigFacility.setListFn,
				requestData,
				apiConfigFacility.mapProperty
			);
		};

		getFetchReportsFacilityList(requestData);
	}, [selectedCity, selectedGroup]);

	useEffect(() => {
		if (!selectedDataType && mappedReportsDataTypes.length > 0) {
			setSelectedDataType(mappedReportsDataTypes[0]);
		}
	}, [mappedReportsDataTypes, selectedDataType]);

	// Rapor tipi değişince çalışacak olan kod
	useEffect(() => {
		if (selectedDataType) {
			const filtered = mappedCurrentlyUnknownData.filter(
				(x) => x.dataTypeId === selectedDataType.value
			);

			// Rapor tipi değişince eşleşen filtered datamızdaki ilk datayı alıyoruz
			setSelectedFilteredData(filtered.at(0));

			// eşleşen dataları bastırmak için
			setFilteredData(filtered);
		}
	}, [mappedCurrentlyUnknownData, selectedDataType]);

	useEffect(() => {
		setSelectedDevice(mappedReportsDeviceList[0]);
	}, [mappedReportsDeviceList]);

	const handleDateRangeSelect = (dates) => {
		setSelectedRange(dates);
	};

	const getTheData = async (requestData) => {
		try {
			const { data } = await axios.post(
				`${backendBaseUrl}${FilterRoutes.getHourlyData}`,
				requestData,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			);

			if (!data.BaseResponse.Success) {
				setBinaryData([]);
			} else {
				setBinaryData(data.HourlyDataList);
			}
		} catch (error) {
			console.error(error);
			setBinaryData([]);
		}
	};

	const handleConfirmForm = async () => {
		requestData.ModbusId = selectedDevice.ModbusId;
		requestData.ModemId = selectedDevice.ModemId;
		requestData.betweenDate = selectedRange;
		requestData.tagId = selectedFilteredData?.value;

		try {
			await getTheData(requestData);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<section className="w-full relative pt-16 z-30">
			<div className="w-full flex">
				{/* Sidebar */}

				<div
					className={`${
						isOpen ? 'translate-x-0' : '-translate-x-full'
					} md:translate-x-0 px-4 mt-4 transition-all duration-300 ease-in-out md:sticky md:top-0 min-h-screen h-full  bg-white dark:bg-[#12263F] z-30 rounded-br-md`}
				>
					<button
						onClick={toggleSidebar}
						className="absolute top-0 -right-8 text-white font-semibold md:hidden focus:outline-none w-10 h-10 rounded-md bg-slate-500 flex items-center justify-center z-40"
					>
						{isOpen ? <BiChevronsLeft /> : <BiChevronsRight />}
					</button>

					<div className="w-full flex-grow md:block mt-4 sticky top-0">
						<ListBox
							data={SelectionOptions.branchGroups}
							label="Tesis Grup"
							custopPlaceHolder="Tüm Gruplar"
							handleChange={handleGroupChange}
						/>

						<ListBox
							data={mappedReportsCityList}
							label="Şehir"
							custopPlaceHolder="Tüm Şehirler"
							handleChange={handleCityChange}
						/>

						<MultipleSelect
							label={'Tesisler'}
							options={mappedReportsFacilityList}
							selectedOptions={selectedOptions}
							setSelectedOptions={setSelectedOptions}
							handleConfirm={handleConfirmForMultipleFacility}
						/>

						<ListBox
							data={mappedReportsDeviceList}
							label="Cihazlar"
							handleChange={handleSelectedDevice}
						/>

						<ListBox
							data={mappedReportsDataTypes}
							label="Rapor Tipi"
							handleChange={handleSetDataType}
						/>

						{selectedDataType &&
							Number(selectedDataType.isHourlyDataAvailable) === 1 && (
								<ListBox
									data={SelectionOptions.dataTypes}
									label="Veri Tipi"
								/>
							)}

						{filteredData.length >= 1 && (
							<ListBox
								data={filteredData}
								label="Data tipi"
								handleChange={handleSelectedDataType}
								selectedValue={selectedFilteredData}
							/>
						)}

						<ListBox
							data={SelectionOptions.graphicDesign}
							label="Gösterim Şekli"
							handleChange={handleGraphicDesign}
						/>

						<div>
							<label
								htmlFor="date-range"
								className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
							>
								Tarih seçiniz
							</label>

							<div className="relative">
								<DateRangePicker
									id="date-range"
									isoWeek
									format="dd/MM/yyyy"
									placement="bottomEnd"
									preventOverflow
									placeholder="Tarih aralığı seçiniz"
									ranges={PredefinedRanges}
									defaultValue={[subDays(new Date(), 7), new Date()]}
									onChange={handleDateRangeSelect}
									disabledDate={afterToday()}
									locale={CalendarLocaleType}
								/>

								<svg
									xmlns="http://www.w3.org/2000/svg"
									className="absolute right-2 top-2 w-5 h-5 text-gray-400 pointer-events-none"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fillRule="evenodd"
										d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
										clipRule="evenodd"
									/>
								</svg>
							</div>
						</div>

						<button
							className="p-2 w-full px-5 py-2 mt-4 text-center mb-2 shadow-md shadow-gray-400 dark:shadow-sm dark:shadow-slate-600 rounded-xl uppercase bg-gradient-to-r from-[#5651e5] to-[#709dff] text-white hover:bg-gradient-to-br"
							onClick={handleConfirmForm}
						>
							Filtrele
						</button>
					</div>
				</div>

				{/* Overlay */}

				{/* <div
          className={`${
            isOpen ? "opacity-75" : ""
          } fixed inset-0 z-10 transition duration-300 ease-in-out`}
          onClick={() => setIsOpen(false)}
        ></div> */}

				{/* Content */}

				<div
					className={`flex-grow md:px-8 py-2 ${
						isOpen
							? 'translate-x-0 opacity-50'
							: '-translate-x-[200px] md:-translate-x-0'
					} transition-all duration-300 ease-in-out`}
				>
					{selectedGraphicDesign === 'tablo' ? (
						<TableForReports data={binaryData} />
					) : (
						<ChartGraph binaryData={binaryData} />
					)}
				</div>
			</div>
		</section>
	);
};

export default ReportPage;
