import { createSlice } from "@reduxjs/toolkit";


export const tooltypeSlice = createSlice({
  name: "tooltypeStatus",
  initialState: {
    showTooltip: true,
    mousePosX: 0,
    mousePosY: 0,
    title: "Tooltip Title",
    description: "",
  },
  reducers: {
    setShowToolType: (state,action)=> {
        const tooltypeData = action.payload;
        if(tooltypeData){
            state.showTooltip=tooltypeData.showTooltip;
            state.mousePosX=tooltypeData.mousePosX;
            state.mousePosY = tooltypeData.mousePosY;
            state.title = tooltypeData.title;
            state.description= tooltypeData.description;
        }
    }
  },
});

export const { setShowToolType } =
tooltypeSlice.actions;
export default tooltypeSlice.reducer;
