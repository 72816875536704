import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendBaseUrl } from '../../../routes';
import { FilterRoutes } from '../../../routes/reports/filters';

export const fetchReportsDataTypeList = createAsyncThunk(
	'fetchReportsDataTypeList',
	async () => {
		const dataTypeList = await axios.get(
			`${backendBaseUrl}${FilterRoutes.dataType}`,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);

		const data = JSON.stringify(dataTypeList.data);
		return JSON.parse(data);
	}
);
