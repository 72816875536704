import React, { useState } from "react";
import FormDropdown from "../../components/base/form-dropdown";
import FormInput from "../../components/base/form-input";
import { boudRate } from "../../mockData/boadRate";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import validationSchema from "../../components/validations/form-edit_device";

function AddDevice() {
  const [selectedValue, setSelectedValue] = useState("1");
  const [transformerRatio, setTransformerRatio] = useState(1);
  const [branchM2, setBranchM2] = useState(100);
  const [isCalled, setIsCalled] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const facility = useSelector((state) => state.falicity) || {};

  const dispatch = useDispatch();

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      deviceTitle: "",
      modemKey: "",
      deviceTypeId: "",
      modbusId: "",
      baudRate: "",
      currentRatio: "",
    },
    onSubmit: async (values) => {
      console.log(values);
    },
    validationSchema,
  });

  const deviceTypes =
    facility?.deviceTypes?.map((type) => {
      return {
        title: type.Title,
        value: type.Title,
      };
    }) || [];

  const modBusAdressList = () => {
    const data = [];
    for (let i = 1; i <= 255; i++) {
      data.push({
        title: i.toString(),
        value: i.toString(),
      });
    }
    return data;
  };

  console.log("deviceTypes", deviceTypes);
  console.log(facility.deviceTypes)

  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
        <FormInput
          labelText={"Cihaz Adı"}
          labelId={"device-title"}
          inputPlaceholder={"Cihaz Adını Yazınız"}
          value={values.deviceTitle}
          handleChange={handleChange}
          isRequiredStar={true}
          handleBlur={handleBlur}
          name="deviceTitle"
          errors={errors.deviceTitle}
          touched={touched.deviceTitle}
        />

        <FormInput
          labelText={"Modem Kodu"}
          labelId={"modem-key"}
          inputPlaceholder={"Modem kodunu yazınız"}
          value={values.modemKey}
          handleChange={handleChange}
          isRequiredStar={true}
          handleBlur={handleBlur}
          name="modemKey"
          errors={errors.modemKey}
          touched={touched.modemKey}
        />

        <div>
          <FormDropdown
            labelText={"Cihaz"}
            options={deviceTypes}
            defaultOption={values.deviceTypeId}
            isRequiredStart={true}
            placeholder={"Bir cihaz seçiniz"}
            name={"deviceTypeId"}
            labelId={"facility-group"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors.deviceTypeId}
            touched={touched.deviceTypeId}
          />
        </div>

        <div>
          <FormDropdown
            labelText={"Modbus Adresi"}
            options={modBusAdressList()}
            defaultOption={values.modbusId}
            isRequiredStart={true}
            placeholder={"Modbus adresini seçiniz"}
            name={"modbusId"}
            labelId={"modbus"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors.modbusId}
            touched={touched.modbusId}
          />
        </div>

        <div className="w-full">
          <FormDropdown
            labelText={"Baud Rate"}
            options={boudRate}
            defaultOption={values.baudRate}
            isRequiredStart={true}
            placeholder={"Baud rate seçiniz"}
            name={"baudRate"}
            labelId={"baud-rate"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors.baudRate}
            touched={touched.baudRate}
          />
        </div>

        <div className="">
          <FormInput
            labelText={"Akım Trafo Oranı"}
            labelId={"current-ratio"}
            inputPlaceholder={"Akım trafosu oranını yazınız"}
            value={values.currentRatio}
            handleChange={handleChange}
            isRequiredStar={true}
            handleBlur={handleBlur}
            name="currentRatio"
            errors={errors.currentRatio}
            touched={touched.currentRatio}
          />
          
        </div>
      </div>

      <p className="w-full px-4 text-xs text-slate-600 dark:text-white">
        <span className="text-orange-400 text-lg">*</span> ile belirtilen
        alanlar doldurulması zorunludur...!
      </p>
      <div className="w-full px-4 flex justify-center">
        <button
          type="button"
          className="w-1/2 mb-6 mt-4 border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline disabled:bg-slate-300 disabled:border-slate-300"
          disabled={true}
        >
          Cihaz Kayıt
        </button>
      </div>
    </>
  );
}

export default AddDevice;
