function ListBox({ data, label, custopPlaceHolder, handleChange, selectedValue }) {
	const selected = (val) =>
		selectedValue ? (selectedValue === val ? 'selected' : '') : val === 0;

	return (
		<div>
			<label
				htmlFor="small"
				className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
			>
				{label}
			</label>

			<select
				id="small"
				className="p-1 min-w-200 w-full mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
				onChange={handleChange}
			>
				{custopPlaceHolder && <option value="">{custopPlaceHolder}</option>}
				{data?.map((item, index) => (
					<option key={index} value={item.value} selected={selected(index)}>
						{item.title}
					</option>
				))}
			</select>
		</div>
	);
}

export default ListBox;
