import React from "react";
import { useLocation } from "react-router-dom";
import { HiHome } from "react-icons/hi";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

function Breadcrumbs() {
  const location = useLocation();

  //console.log(location.pathname.split("/"));

  // breadcrumb list items
  const breadcrumbs = [
    { path: "/", label: "Ana Sayfa" },
    // add more items as needed
    { path: location.pathname, label: location.pathname.split("/")[1] },
  ];
  //console.log(breadcrumbs[1].label);
  return (
    <nav
      className="absolute top-14 left-8 md:left-16 flex z-40"
      aria-label="breadcrumbs"
    >
      <ul className="flex items-center space-x-1">
        <li>
          <HiHome className="text-gray-500" />
        </li>
        {breadcrumbs.map((breadcrumb, index) => (
          <li key={index}>
            {index !== breadcrumbs.length - 1 ? (
              <Link
                to={breadcrumb.path}
                className="text-gray-500 hover:text-gray-700 text-sm flex items-center justify-center"
              >
                {breadcrumb.label}
                {breadcrumbs[1].label && (
                  <HiChevronRight className="text-lg ml-1" />
                )}
              </Link>
            ) : (
              <span className="text-gray-700 -ml-1 pl-0 text-sm">
                {breadcrumb.label}
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
