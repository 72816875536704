import { MdLocationOn } from 'react-icons/md';
import FormInput from '../../components/base/form-input';
import FormDropdown from '../../components/base/form-dropdown';
import { cities } from '../../mockData/city';
import { facilityGroups } from '../../mockData/facilityGroups';
import InfoIcon from '../base/icons/info-icon';
import validationSchema from '../validations/form-edit_facility';
import { useFormik } from 'formik';

function FacilityEditForm({
	editFacilityData,
	editFacilityIds,
	handleUpdateFacility,
	user,
}) {
	const { facilityId } = editFacilityIds;

	const initialValues = {
		facilityTitle: editFacilityData ? editFacilityData?.FacilityTitle : '',
		city: editFacilityData ? editFacilityData?.City : '',
		state: editFacilityData ? editFacilityData?.State : '',
		latitude: editFacilityData ? editFacilityData?.Latitude : '',
		longitude: editFacilityData ? editFacilityData?.Longitude : '',
		groupTag: editFacilityData ? editFacilityData?.GroupTag : '',
		modemKey: editFacilityData ? editFacilityData?.ModemKey : '',
	};
	const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik(
		{
			initialValues: initialValues,
			onSubmit: async (values) => {
				if (!values.latitude) {
					values.latitude = '41.0197';
				}
				if (!values.longitude) {
					values.longitude = '28.9261';
				}
				const facilityRequest = {
					userId: user.UserId,
					facilityId: facilityId,
					facilityTitle: values.facilityTitle,
					city: values.city,
					state: values.state,
					latitude: values.latitude,
					longitude: values.longitude,
					groupTag: values.groupTag,
					modemKey: values.modemKey,
				};
				handleUpdateFacility(facilityRequest);
			},
			validationSchema,
		}
	);

	const TurkeyCities = cities.map((city) => {
		return {
			title: city.value,
			value: city.value,
		};
	});
	const facility_groups = facilityGroups.map((group) => {
		return {
			title: group.title,
			value: group.value,
		};
	});

	return (
		<>
			<form
				onSubmit={handleSubmit}
				className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 mt-4"
			>
				<FormInput
					labelText={'Tesis Adı'}
					labelId={'facility-title'}
					inputPlaceholder={'Tesis Adını Yazınız'}
					value={values.facilityTitle}
					handleChange={handleChange}
					isRequiredStar={true}
					handleBlur={handleBlur}
					name="facilityTitle"
					errors={errors.facilityTitle}
					touched={touched.facilityTitle}
				/>

				<FormDropdown
					labelText={'Şehir'}
					options={TurkeyCities}
					isRequiredStart={true}
					placeholder={'Şehir Seçiniz'}
					defaultOption={values.city}
					name={'city'}
					labelId={'city'}
					handleChange={handleChange}
					handleBlur={handleBlur}
					errors={errors.city}
					touched={touched.city}
				/>

				<FormInput
					labelText={'Adres'}
					labelId={'state'}
					name={'state'}
					inputPlaceholder={'Adresi giriniz'}
					value={values.state}
					handleChange={handleChange}
					isRequiredStar={false}
					errors={errors.state}
					touched={touched.state}
				/>

				<FormDropdown
					labelText={'Tesis Grup'}
					options={facility_groups}
					defaultOption={values.groupTag}
					isRequiredStart={true}
					placeholder={'Tesis grubu seçiniz'}
					name={'groupTag'}
					labelId={'facility-group'}
					handleChange={handleChange}
					handleBlur={handleBlur}
					errors={errors.groupTag}
					touched={touched.groupTag}
				/>

				<FormInput
					labelText={'Modem Kodu'}
					labelId={'modem-key'}
					inputPlaceholder={'Modem kodunu yazınız'}
					value={values.modemKey}
					handleChange={handleChange}
					isRequiredStar={true}
					handleBlur={handleBlur}
					name="modemKey"
					errors={errors.modemKey}
					touched={touched.modemKey}
				/>

				<div className="">
					<label
						htmlFor="countries"
						className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
					>
						Konum
					</label>
					<div className="flex">
						<div className=" w-10 h-10 bg-slate-300 hover:bg-slate-400 flex items-center justify-center rounded-l-md transition duration-500 ease">
							<MdLocationOn className="w-8 h-8 text-slate-700" />
						</div>
						<span className="w-full h-10 border border-slate-300 bg-white flex items-center px-2 rounded-r-md bg-gray-50  dark:bg-[#374151] dark:border-gray-600">
							<div className="flex items-center">
								<div>
									<input
										type="text"
										id="latitude"
										className="w-1/2 dark:text-white text-gray-900 text-sm focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										placeholder="Enlem giriniz"
										value={values.latitude}
										onChange={handleChange}
									/>

									<input
										type="text"
										id="longitude"
										className="w-1/2 dark:text-white text-gray-900 text-sm border-l pl-1 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										placeholder="Boylam giriniz"
										value={values.longitude}
										onChange={handleChange}
									/>
								</div>
							</div>
						</span>
						<div className="ml-1 h-full my-auto">
							<div className="relative">
								<InfoIcon />
							</div>
						</div>
					</div>
					<div className="flex flex-cols">
						{errors.latitude && touched && (
							<span className="text-red-500 text-sm">
								{errors.latitude}
							</span>
						)}
						{errors.longitude && touched && (
							<span className="text-red-500 text-sm">
								{errors.longitude}
							</span>
						)}
					</div>
				</div>
				<div></div>
				<div className="w-full flex items-center justify-center">
					<button
						type="submit"
						className="w-1/2 mb-6 mt-4 border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline"
					>
						Tesis Güncelle
					</button>
				</div>
			</form>
		</>
	);
}

export default FacilityEditForm;
