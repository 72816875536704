import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendBaseUrl } from '../../../routes';
import { FilterRoutes } from '../../../routes/reports/filters';

export const fetchReportsFacilityList = createAsyncThunk(
	'fetchReportsFacilityList',
	async (requestData) => {
		const facilityListData = await axios.post(
			`${backendBaseUrl}${FilterRoutes.facilityGet}`,
			requestData,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);

		const data = JSON.stringify(facilityListData.data);
		return JSON.parse(data);
	}
);
