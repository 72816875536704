import { createSlice } from '@reduxjs/toolkit';
import { fetchReportsFacilityList } from '../../../../services/reports/filters/facility';

export const ReportsFiltersFacility = createSlice({
	name: 'ReportsFiltersFacility',
	initialState: {
		ReportsFiltersFacilityList: null,
		isLoading: false,
		error: null,
	},

	reducers: {},

	extraReducers: (builder) => {
		builder.addCase(fetchReportsFacilityList.pending, (state) => {
			state.isLoading = true;
		});

		builder.addCase(fetchReportsFacilityList.fulfilled, (state, action) => {
			const { BaseResponse, ReportsFacilityList } = action.payload;

			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.ReportsFacilityList = ReportsFacilityList;
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.isLoading = false;
		});

		builder.addCase(fetchReportsFacilityList.rejected, (state, action) => {
			state.error = action.error.message;
			state.isLoading = false;
		});
	},
});

export default ReportsFiltersFacility.reducer;
