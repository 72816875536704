import { useState } from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RxReader } from "react-icons/rx";

const fakeDeviceList = [
  {
    cihaz_adi: "Reaktif Röle",
    modem_no: "120210001",
    cihaz: "Grup Arge SVC12C3L",
    modbus: "255",
    baud_rate: "19200",
    status: true,
  },
  {
    cihaz_adi: "Reaktif Röle",
    modem_no: "120210001",
    cihaz: "Grup Arge SVC12C3L",
    modbus: "255",
    baud_rate: "19200",
    status: false,
  },
  {
    cihaz_adi: "Reaktif Röle",
    modem_no: "120210001",
    cihaz: "Grup Arge SVC12C3L",
    modbus: "255",
    baud_rate: "19200",
    status: true,
  },
];

function AddDevice() {
  const [selectedValue, setSelectedValue] = useState("1");
  const [veriler, setVeriler] = useState(fakeDeviceList);
  const [transformerRatio, setTransformerRatio] = useState(1);

  // 10'dan az veri varsa, diğer satırları boş dizilerle doldurun
  if (veriler.length < 10) {
    const bosDiziler = Array(10 - veriler.length).fill({});
    veriler.push(...bosDiziler);
  }

  const options = [];
  for (let i = 1; i <= 255; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  function handleChange(event) {
    setSelectedValue(event.target.value);
  }
  return (
    <section className="pt-16 mx-8">
      {/* Card */}
      <div className="min-w-310 shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8">
        <h3 className="mx-4 pt-4 w-full text-center text-slate-600 dark:text-white tracking-wider text-lg font-medium">
          Cihaz Ekleme
        </h3>
        {/* Form */}
        <form action="">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 mt-4">
            <div className="">
              <label
                htmlFor="device-name"
                className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
              >
                Cihaz Adı
              </label>
              <div className="flex items-center">
                <input
                  type="text"
                  name="device-name"
                  placeholder="Cihaz Adını Yazınız"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <span className="ml-1 text-orange-400">*</span>
              </div>
            </div>

            <div>
              <label
                htmlFor="modem-no"
                className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
              >
                Modem Kodu
              </label>
              <div className="flex items-center">
                <select
                  id="modem-no"
                  name="modem-no"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Modem A</option>
                  <option>Modem B</option>
                  <option>Modem C</option>
                  <option>Modem D</option>
                  <option>Modem E</option>
                </select>
                <span className="ml-1 text-orange-400">*</span>
              </div>
            </div>

            <div>
              <label
                htmlFor="device"
                className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
              >
                Cihaz
              </label>
              <div className="flex items-center">
                <select
                  id="device"
                  name="device"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>Cihaz A</option>
                  <option>Cihaz B</option>
                  <option>Cihaz C</option>
                  <option>Cihaz D</option>
                  <option>Cihaz E</option>
                </select>
                <span className="ml-1 text-orange-400">*</span>
              </div>
            </div>

            <div>
              <label
                htmlFor="modbus-number"
                className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
              >
                Modbus Adresi
              </label>
              <div className="flex items-center">
                <select
                  id="countries"
                  name="modbus-number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={selectedValue}
                  onChange={handleChange}
                >
                  {options}
                </select>
                <span className="ml-1 text-orange-400">*</span>
              </div>
            </div>

            <div>
              <label
                htmlFor="device"
                className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
              >
                Baud Rate
              </label>
              <div className="flex items-center">
                <select
                  id="device"
                  name="device"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option>9600</option>
                  <option>19200</option>
                  <option>38400</option>
                  <option>57600</option>
                  <option>115200</option>
                </select>
                <span className="ml-1 text-orange-400">*</span>
              </div>
            </div>

            <div className="">
              <label
                htmlFor="current-transformer-ratio"
                className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
              >
                Akım Trafo Oranı
              </label>
              <div className="flex items-center">
                <input
                  type="text"
                  name="current-transformer-ratio"
                  placeholder=""
                  value={transformerRatio}
                  onChange={(e) => setSelectedValue(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <span className="ml-1 text-orange-400">*</span>
              </div>
            </div>
          </div>
          <p className="w-full px-4 text-xs text-slate-600 dark:text-white">
            <span className="text-orange-400 text-lg">*</span> ile belirtilen
            alanlar doldurulması zorunludur...!
          </p>
          <div className="w-full px-4 flex justify-center">
            <button
              type="button"
              class="w-1/2 mb-6 mt-4 border border-green-500 bg-green-500 text-white rounded-md px-4 py-2 m-2 transition duration-500 ease select-none hover:bg-green-600 focus:outline-none focus:shadow-outline shadow-lg shadow-green-300"
            >
              Cihaz Kayıt
            </button>
          </div>
        </form>
      </div>
      {/* Table */}
      <div className="w-full shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8 overflow-x-auto">
        <table className="min-w-[1024px] w-full">
          <thead className="">
            <tr className="text-white tracking-wider bg-sky-500 rounded-t-md">
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-sm text-center py-2 rounded-tl-md">
                Etiket
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Modem No
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Cihaz
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Modbus
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Baud Rate
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Düzenle
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Sil
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Durum
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm rounded-tr-md">
                Cihaz Oku
              </th>
            </tr>
          </thead>
          <tbody>
            {veriler.map((veri, index) => (
              <tr
                key={index}
                className={`w-full ${
                  index % 2 === 0
                    ? "bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500"
                    : "bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500"
                } hover:bg-sky-100`}
              >
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm min-h-44 h-[40px]">
                  {veri.cihaz_adi || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.modem_no || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.modbus || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.baud_rate || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz_adi && (
                    <button
                      type="button"
                      class="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-xs px-3 py-1 text-center mr-2 mb-1 flex gap-2 items-center"
                    >
                      <FaEdit />
                      Düzenle
                    </button>
                  )}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz_adi && (
                    <button
                      type="button"
                      class="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-xs px-3 py-1 text-center mr-2 mb-1 flex items-center gap-2"
                    >
                      <MdDelete />
                      Sil
                    </button>
                  )}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz_adi && (
                    <div
                      className={`w-4 h-4 rounded-full ${
                        veri.status === true ? "bg-green-600" : "bg-red-600"
                      }`}
                    ></div>
                  )}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {veri.cihaz_adi && (
                    <button
                      type="button"
                      class="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-xs px-3 py-1 text-center mr-2 mb-1 flex items-center gap-2"
                    >
                      <RxReader />
                      Veri Oku
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default AddDevice;
