import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { fetchLogin } from '../../services/auth';

export const authSlice = createSlice({
	name: 'auth',
	initialState: {
		user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null,
		isAuthenticated: Cookies.get('isAuthenticated')
			? JSON.parse(Cookies.get('isAuthenticated'))
			: false,
		loading: false,
		error: null,
		rememberMeChecked: localStorage.getItem('antcomRemember')
			? JSON.parse(localStorage.getItem('antcomRemember'))
			: false,
	},
	reducers: {
		errorReset: (state) => {
			state.error = null;
		},
		setRememberMe: (state, action) => {
			state.rememberMeChecked = !state.rememberMeChecked;
			localStorage.setItem('antcomRemember', state.rememberMeChecked);
		},
		signOut: (state) => {
			state.rememberMeChecked = false;
			state.isAuthenticated = false;
			Cookies.remove('user');
			Cookies.remove('isAuthenticated');
			localStorage.removeItem('antcomRemember');
		},
	},
	extraReducers: (builder) => {
		//postUser
		builder.addCase(fetchLogin.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(fetchLogin.fulfilled, (state, action) => {
			const { BaseResponse, UserInfo } = action.payload;
			const expiresInDays = 1; // Cookie'nin 1 gün sonra silinmesi için
			if (BaseResponse.Success) {
				state.isAuthenticated = true;
				state.user = UserInfo;
				state.rememberMeChecked &&
					Cookies.set('user', JSON.stringify(UserInfo), {
						expires: expiresInDays,
					});
				state.rememberMeChecked &&
					Cookies.set('isAuthenticated', JSON.stringify(true), {
						expires: expiresInDays,
					});
				state.error = null;
			} else {
				state.error = BaseResponse.ResponseMessage;
			}
			state.loading = false;
		});
		builder.addCase(fetchLogin.rejected, (state, action) => {
			console.log(action.error);
			state.error = action.error.message;
			state.loading = false;
		});
	},
});

export const { errorReset, setRememberMe, signOut } = authSlice.actions;
export default authSlice.reducer;
