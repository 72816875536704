export const homeRoute = '/';
export const notFoundRoute = '*';

export const AuthLocalRoutes = {
	login: '/login',
};

export const FacilityLocalRoutes = {
	tesislerim: '/facilities',
	// tesislerimDeneme:"/tesislerim1234",
	tesisEkle: '/add-facility',
	updateFacility: '/facilities/update-facility/:facilityId/:modemId/:deviceId',
	// cihazEkle:"/add-device",
	facilityEdit: '/facilities/facility-edit',
	facilityAdd: '/facilities/facility-add',
};

export const ReportLocalRoutes = {
	raporlar: '/raporlar',
};
