import React from "react";

function FormInput({
  value,
  labelText,
  inputPlaceholder,
  isRequiredStar,
  handleChange,
  handleBlur,
  name,
  labelId,
  errors,
  touched,
}) {
  return (
    <>
      <div className="">
        {labelText && (
          <label
            // htmlFor="branch-office-name"
            id={labelId}
            className="block mb-2 text-sm font-medium text-slate-600 dark:text-white"
          >
            {labelText}
          </label>
        )}
        <div className="flex flex-col w.full">
          <div className="flex items-center w-full">
            <input
              type="text"
              id={labelId}
              name={name}
              value={value || ""}
              onChange={(e) => handleChange(e)}
               onBlur={handleBlur}
              // name="branch-office-name"
              placeholder={inputPlaceholder}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            {isRequiredStar && <span className="ml-1 text-orange-400">*</span>}
          </div>
          {errors && touched && (<span className="text-red-500 text-sm">{errors}</span>)}
          
        </div>
      </div>
    </>
  );
}

export default FormInput;
