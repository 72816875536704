import { BsFiletypePdf } from 'react-icons/bs';
import Table from '../../components/facility/table/Table';

function Facility() {
	return (
		<section className="pt-16 mx-8">
			<div className="w-full border-b-2 border-slate-500 dark:border-b-white flex items-center justify-end">
				<BsFiletypePdf className="w-10 h-10 text-white bg-pinkCustom rounded-md p-1 m-1 hover:cursor-pointer" />
			</div>

			<div className="w-full shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8 overflow-x-auto">
				<Table />
			</div>
		</section>
	);
}

export default Facility;
