import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { FacilityRoutes } from '../routes/facility';
import { backendBaseUrl } from '../routes';

export const fetchFacilityList = createAsyncThunk(
	'getFacilityList/getData',
	async (facilityRequest) => {
		const facilityListData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.facilityGet}`,
			facilityRequest,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(facilityListData.data);
		// console.log(JSON.parse(data));
		return JSON.parse(data);
		//return authData.data;
	}
);

export const getFacilityDetails = createAsyncThunk(
	'fetchFacilityDetails/getData',
	async (facilityRequest) => {
		const facilityDetailsData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.facilityDetails}`,
			facilityRequest,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(facilityDetailsData.data);
		return JSON.parse(data);
	}
);

export const addFacilityAdd = createAsyncThunk(
	'fetchFacility/setData',
	async (facilityData) => {
		const addFacilityData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.facilityAdd}`,
			facilityData,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(addFacilityData.data);
		return JSON.parse(data);
	}
);

export const getDeviceTypesService = createAsyncThunk(
	'fetchDeviceTypes/getData',
	async () => {
		const getDeviceTypesData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.getDeviceTypes}`,
			null,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(getDeviceTypesData.data);
		return JSON.parse(data);
	}
);

export const addDeviceService = createAsyncThunk(
	'addDevice/setData',
	async (deviceData) => {
		const addDeviceData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.deviceAdd}`,
			deviceData,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(addDeviceData.data);
		return JSON.parse(data);
	}
);

export const getModemAndDevicesService = createAsyncThunk(
	'fetchModenAndDeviceList/getData',
	async (request) => {
		const modemAndDeviceData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.getModemAndDevices}`,
			request,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(modemAndDeviceData.data);
		return JSON.parse(data);
	}
);

export const deleteDevice = createAsyncThunk(
	'fetchDeleteDevice/setData',
	async (request) => {
		//modemId,devicId,modbusId is required
		console.log(request);
		const deviceData = await axios.post(
			`${backendBaseUrl}${FacilityRoutes.deleteDevice}`,
			request,
			{ headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
		);
		const data = JSON.stringify(deviceData.data);
		return JSON.parse(data);
	}
);
