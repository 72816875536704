import {useEffect, useState} from "react";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { RxReader } from "react-icons/rx";
import Swal from 'sweetalert2'
import Loading from "../../pages/Loading";
import { useDispatch } from "react-redux";
import { deleteDevice } from "../../services/facility";


function TableDeviceList({
  currentModemAndDeviceList,
  handleSetEditingDeviceData,
  facility
}) {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();

  const handleSetData = (device) => {
    const data = {
      DeviceId: device.DeviceId,
      DeviceTitle: device.DeviceTitle,
      DeviceTypeId: device.DeviceTypeId,
      DeviceTypeTitle: device.DeviceTypeTitle,
      ModbusId: device.ModbusId,
      ModemId: device.ModemId,
      ModemKey: device.ModemKey,
      BaudRate: device.BaudRate,
      CurrentRatio: device.CurrentRatio,
    };
    handleSetEditingDeviceData(data);
  };

  const handleDeleteDevice = (device) => {
    const deleteRequest = {
      deviceId:device.DeviceId,
      modemId:device.ModemId,
      modbusId:device.ModbusId
    }
    dispatch(deleteDevice(deleteRequest))
  }
  useEffect( () => {
    setData(currentModemAndDeviceList);
  },[currentModemAndDeviceList])
  
  return (
    <>
    {facility.isLoading && (<Loading />)}
      <div className="w-full shadow-md rounded-lg bg-white dark:bg-bgCardDark my-8 overflow-x-auto">
        <table className="min-w-[1024px] w-full">
          <thead className="">
            <tr className="text-white tracking-wider bg-sky-500 rounded-t-md">
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2 text-sm py-2 rounded-tl-md">
                Etiket
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2  text-sm">
                Modem No
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2  text-sm">
                Cihaz
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2  text-sm">
                Modbus
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300 text-start px-2 text-sm">
                Baud Rate
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Düzenle
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Sil
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm">
                Durum
              </th>
              <th className="border-b border-gray-400 dark:border-b-gray-300  text-sm rounded-tr-md">
                Cihaz Oku
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((device, index) => (
              <tr
                key={index}
                className={`w-full ${
                  index % 2 === 0
                    ? "bg-100 dark:bg-bgDarkSoftTableRow dark:hover:bg-slate-500"
                    : "bg-white dark:bg-bgDarkLightTableRow dark:hover:bg-slate-500"
                } hover:bg-sky-100`}
              >
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm min-h-44 h-[40px]">
                  {device?.DeviceTitle || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {device?.ModemKey || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {device?.DeviceTypeTitle || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {device?.ModbusId || ""}
                </td>
                <td className="py-1.5 text-slate-950 dark:text-white pl-2 text-sm">
                  {device?.BaudRate || ""}
                </td>
                <td className="py-1 text-slate-950 dark:text-white pl-2 text-sm">
                  {device?.DeviceTitle && (
                    <button
                      type="button"
                      className="h-full w-full text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex gap-2 items-center justify-center"
                      onClick={() => handleSetData(device)}
                    >
                      <FaEdit />
                      Düzenle
                    </button>
                  )}
                </td>
                <td className="py-1 text-slate-950 dark:text-white px-2 text-sm">
                  {device?.DeviceTitle && (
                    <button
                      type="button"
                      className="w-full text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex items-center gap-2 justify-center"
                    onClick={()=> {
                      Swal.fire({
                        title: 'Cihaz Siliniyor ?',
                        text: "Cihazı silmek istediğinize emin misiniz ?",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Evet',
                        cancelButtonText:"Hayır"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDeleteDevice(device)
                          setData((prevData) => prevData.filter((d) => d.DeviceId !== device.DeviceId));
                          Swal.fire(
                            'Cihaz Silindi',
                            'Cihaz başarıyla silindi !',
                            'success'
                          )
                        }
                      })
                    }}
                    >
                      <MdDelete />
                      Sil
                    </button>
                  )}
                </td>
                <td className="h-full w-full py-1 text-slate-950 dark:text-white pl-2 text-sm flex items-center justify-center">
                  {device?.DeviceTitle && (
                    <div
                      className={`w-4 h-4 mt-2 rounded-full ${
                        device.status === true ? "bg-green-600" : "bg-red-600"
                      }`}
                    ></div>
                  )}
                </td>
                <td className="py-1 text-slate-950 dark:text-white  px-2 text-sm">
                  {device?.DeviceTitle && (
                    <button
                      type="button"
                      className="w-full text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-md text-xs px-3 py-1 text-center mr-2 mb-1 flex items-center justify-center gap-2"
                    >
                      <RxReader />
                      Veri Oku
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TableDeviceList;
