import { createAsyncThunk } from '@reduxjs/toolkit';
import { backendBaseUrl } from '../../../routes';
import { FilterRoutes } from '../../../routes/reports/filters/';
import axios from 'axios';

export const fetchReportsCityList = createAsyncThunk(
	'fetchReportsCityList',
	async (requestData) => {
		const cityListData = await axios.post(
			`${backendBaseUrl}${FilterRoutes.cityGet}`,
			requestData,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		);
		const data = JSON.stringify(cityListData.data);
		return JSON.parse(data);
	}
);
